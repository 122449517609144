import BaseSearchFilter from './BaseSearchFilter'

class ItemSortFilter extends BaseSearchFilter {
  /** @type {number} */
  #batchId = null

  /** @type {number} */
  #packageScanId = null

  /** @type {number} */
  #userId = null

  /** @type {string} */
  #itemAction = ''

  /** @type {string} */
  #status = 'OPEN'

  constructor() {
    super()
    this.#batchId = this.batchId
    this.#packageScanId = this.packageScanId
    this.#userId = this.userId
    this.#itemAction = this.itemAction
    this.#status = this.status
  }

  setDefaultFilters() {
    this.accountNo = null
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.batchId = null
    this.packageScanId = null
    this.userId = null
    this.itemAction = 'ALL'
    this.status = 'OPEN'
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
  }

  /**
   * The complete ItemScanFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {number} batchId - BatchId.
   * @property {number} packageScanId - PackageScanId.
   * @property {number} userId - UserId.
   * @property {number} itemAction - ItemAction.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      batchId: this.batchId,
      packageScanId: this.packageScanId,
      userId: this.userId,
      itemAction: this.itemAction,
      status: this.status,
    }
    return filter
  }

  /** @returns {string} */
  get batchId() {
    return this.#batchId
  }

  /** @param {string} value */
  set batchId(value) {
    this.#batchId = value
  }

  /** @returns {string} */
  get packageScanId() {
    return this.#packageScanId
  }

  /** @param {string} value */
  set packageScanId(value) {
    this.#packageScanId = value
  }

  /** @returns {string} */
  get userId() {
    return this.#userId
  }

  /** @param {string} value */
  set userId(value) {
    this.#userId = value
  }

  /** @returns {string} */
  get itemAction() {
    return this.#itemAction
  }

  /** @param {string} value */
  set itemAction(value) {
    this.#itemAction = value
  }

  /** @returns {string} */
  get status() {
    return this.#status
  }

  /** @param {string} value */
  set status(value) {
    this.#status = value
  }
}

export default new ItemSortFilter()
