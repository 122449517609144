<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="row">
          <div class="form-group col-sm-6 col-lg-2">
            <label for="select-item-action">Item Action</label>
            <dx-util-select-box
              id="select-item-action"
              v-model="moveInventoryData.itemAction"
              :data-source="itemActions"
              display-expr="text"
              value-expr="value"
            />
          </div>
          <div class="form-group col-sm-6 col-lg-2">
            <label for="select-store-id">Store</label>
            <dx-util-select-box
              id="select-store-id"
              v-model="moveInventoryData.storeId"
              :data-source="stores"
              display-expr="text"
              value-expr="value"
              placeholder="Store"
            />
          </div>
          <div class="form-group col-sm-6 col-lg-4">
            <label for="select-plan-id">Plan</label>
            <dx-util-select-box
              id="select-plan-id"
              v-model="moveInventoryData.planId"
              :data-source="plans"
              display-expr="text"
              value-expr="value"
              placeholder="Plans"
            />
          </div>
          <div class="form-group col-sm-6 col-lg-2">
            <label for="text-box-location">Location</label>
            <dx-util-text-box
              id="text-box-location"
              v-model="moveInventoryData.location"
              placeholder="Location"
            />
          </div>
          <div class="form-group col-sm-6 col-lg-2">
            <label for="text-box-parent-location">Create LPN</label>
            <div class="align-middle">
              <dx-util-check-box
                v-model="moveInventoryData.assignLpn"
                class="text-warning font-weight-bolder"
                text="Create LPN"
              />
          </div>
          </div>
        </div>
      </div>
      <div class="col-12 px-0">
        <dx-data-grid
          id="moveInventoryGrid"
          ref="moveInventoryGridRef"
          :height="setHeight"
          :data-source="dataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <!--region DataGird base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="false" :sorting="false" :filtering="false" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!--endregion DataGird base components -->
          <dx-column data-field="itemAction" caption="Action" />
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column data-field="title" cell-template="titleCellTemplate" :min-width="300" />
          <template #titleCellTemplate="{data}">
            <div class="dx-product-title">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="productType" caption="Type" />
          <dx-column data-field="tracking" />
          <dx-column data-field="palletId" caption="Pallet Id" />
          <dx-column data-field="orderNumber" />
          <dx-column data-field="upcCode" caption="UPC" />
          <dx-column data-field="asin" caption="ASIN" />
          <!-- <dx-column data-field="sortedQty" /> -->
          <!-- <dx-column data-field="shippedQty" /> -->
          <dx-column data-field="quantity" alignment="left" />
          <dx-column data-field="moveQuantity" alignment="left" cell-template="moveQuantityCellTemplate" />
          <template #moveQuantityCellTemplate="{data}">
            <dx-util-number-box
              v-if="data.value"
              :id="`quantity-${data.data.id}`"
              :ref="`quantity-${data.data.id}`"
              :value="data.value"
              @value-changed="onValueChangeMoveQuantity($event, data.data)"
            />
          </template>
          <dx-column data-field="storeName" />
          <!-- region Actions -->
          <dx-editing
              :allow-deleting="true"
              :use-icons="true"
          />
          <dx-column type="buttons" caption="Actions">
            <dx-button name="delete" css-class="text-danger" hint="Delete this sorted item." />
          </dx-column>
          <!-- endregion -->
        </dx-data-grid>
      </div>
      <div class="col-12 py-1 fixed-bottom">
        <dx-util-button
            id="acceptPopupButton"
            text="Save"
            type="default"
            styling-mode="contained"
            @click="onClickMoveInventory()"
          />
      </div>
      <div class="text-center">
        <svg id="barcodePreview" ref="barcodePreviewRef"></svg>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
import useBarcodeHelper from '@/libs/app/barcode-helper'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import ArrayStore from 'devextreme/data/array_store'
import DataSource from 'devextreme/data/data_source'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import sortItemService from '@/http/requests/inbound/sort-item.service'
import planManagementService from '@/http/requests/plan/planManagementService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import { getItemActionEnumList } from '@/enums/item-action.enum'
import { Notify } from '@robustshell/utils/index'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

const dataSource = new DataSource({
  store: new ArrayStore({
    key: 'id',
    data: [],
  }),
})

export default {
  mixins: [GridBase],
  props: {
    moveInventoryData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { downloadBarcodeImage } = useBarcodeHelper()
    return {
      downloadBarcodeImage,
    }
  },
  data() {
    return {
      itemActions: getItemActionEnumList(),
      dataSource,
      stores: [],
      plans: [],
      defaultImg: defaultImageUrl,
      barcodeOptions: {
        format: 'CODE128',
        width: 2,
        height: 150,
        displayValue: true,
        text: 'PrepShipHub',
        fontOptions: '',
        font: 'monospace',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: 20,
        background: '#ffffff',
        lineColor: '#000000',
        margin: 10,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
      },
    }
  },
  computed: {
    dataGrid() {
      const dataGridIns = this.$refs.moveInventoryGridRef.instance
      return dataGridIns
    },
    setHeight() {
      return 'window.innerHeight / 1.6'
    },
  },
  mounted() {
    this.setStoreData()
    this.getStores()
    this.getPlans()
    this.lpnOptions()
  },
  beforeDestroy() {
    this.dataGrid.dispose()
    this.dataGrid.remove()
  },
  methods: {
    lpnOptions() {
      const target = this.$refs.barcodePreviewRef
      target.classList.remove('d-none')
    },
    setStoreData() {
      this.moveInventoryData.selectedItems.forEach((item, index) => {
        const newItem = {
          ...item,
          moveQuantity: item.quantity,
        }
        this.dataSource.store().clear()
        this.dataSource.store().push([{ type: 'insert', data: newItem, index: index }])
      })
    },
    getStores() {
      commonEntityService
        .fetchStoresByParentCompany(this.moveInventoryData.companyId)
        .then(result => {
          const data = result.data
          this.setStores(data)
        })
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      if (data.length > 1) {
        this.stores.push({
          value: 0,
          text: 'Select Store',
        })
      }
      data.forEach(element => {
        this.stores.push({
          value: element.id,
          text: element.name,
          isAuthorized: element.isAuthorized,
        })
      })
      if (this.stores.length === 1) {
        this.moveInventoryData.storeId = data[0].value
      }
    },
    getPlans() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.warehouseId = this.moveInventoryData.warehouseId
      PlanSearchFilter.companyId = this.moveInventoryData.companyId
      const searchFilter = PlanSearchFilter.getFilters()
      const pageable = 'page=0&size=200'
      planManagementService
        .fetchAllUserPlansByQuery(searchFilter, pageable)
        .then(result => {
          const data = result.data.content
          return data
        })
        .then(data => {
          data.forEach(element => {
            this.plans.push({
              value: element.id,
              text: `${element.planCategory.toUpperCase()}:${element.planName}:${element.planType}`,
            })
          })
        })
    },
    onValueChangeMoveQuantity(e, data) {
      const newQty = e.value
      if (data.quantity < newQty) {
        Notify.error('Move Quantity cannot be greater than quantity.')
        return
      }
      if (newQty <= 0) {
        Notify.error('Move Quantity should be at least 1.')
        return
      }
      const payload = {
        ...data,
        moveQuantity: newQty,
      }
      this.moveQuantityUpdate(payload).then(() => {
        this.dataGrid.refresh()
      })
    },
    async moveQuantityUpdate(payload) {
      this.dataSource
        .store()
        .update(payload.id, payload)
        .then(
          dataObj => {
            Notify.success(`ASIN: ${dataObj.asin} - Move Quantity has been updated successfully.`)
          },
          error => {
            Notify.error(error)
          },
        )
    },
    onClickMoveInventory() {
      const dataSourceItems = this.dataSource.items()
      const items = dataSourceItems.map(item => ({
        id: item.id,
        totalQuantity: item.quantity,
        moveQuantity: item.moveQuantity,
      }))
      const data = {
        companyId: this.moveInventoryData.companyId,
        storeId: this.moveInventoryData.storeId,
        warehouseId: this.moveInventoryData.warehouseId,
        planId: this.moveInventoryData.planId,
        itemAction: this.moveInventoryData.itemAction,
        location: this.moveInventoryData.location,
        parentLocation: this.moveInventoryData.parentLocation,
        assignLpn: this.moveInventoryData.assignLpn,
        items,
      }
      sortItemService.moveItemToInventory(data)
        .then(result => {
          const lpn = result.data.body
          if (lpn && lpn.lpn) {
            const target = this.$refs.barcodePreviewRef
            target.classList.add('d-none')
            this.downloadBarcodeImage(target, lpn.lpn)
          }
        })
        .then(() => {
          this.$emit('on-action-move-inventory')
        })
    },
  },
}
</script>

<style>

</style>
