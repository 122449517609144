import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchAll(pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findAll(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async findByQuery(data, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.company.Controller.findByQuery(pageable), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchCompanyAndStoresByAccountNo(accountNo) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findCompanyAndStoresByAccountNo(accountNo))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchByAccountNo(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findByAccountNo(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async getCompanyCount() {
    const result = await axios.get(endpoints.company.Controller.getCompanyCount())
    return result.data.body
  },

  async fetchByHub(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findByHub(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async findByCompanyType(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findByCompanyType(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllMatchingNameAndType(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findAllMatchingNameAndType(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async findAllInfoByAccountNo(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findAllInfoByAccountNo(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async findCompanyAllInfoByCompanyId(companyId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findCompanyAllInfoByCompanyId(companyId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchUsersByCompanyId(companyId, pageable) {
    const result = await axios.get(endpoints.company.Controller.findUsersByCompanyId(companyId, pageable))
    return {
      data: result.data.body,
    }
  },

  async fetchAccountManagerByAccountNo(accountNo) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findAccountManagerByAccountNo(accountNo))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async fetchAuthoritiesByCompanyType(companyType) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findAuthoritiesByCompanyType(companyType))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllEcommerSiteList() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.eCommerceSiteController.findList())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllEcommerSiteActiveList() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.eCommerceSiteController.findListActive())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deleteCompanyUserByUserId(userId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.company.Controller.deleteCompanyUserByUserId(userId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createCompanyUser(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.company.Controller.createCompanyUser(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateCompanyUser(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.company.Controller.updateCompanyUser(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.company.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.company.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async upsert(data) {
    const result = await axios.put(endpoints.company.Controller.upsert(), data)
    return result.data.body
  },

  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.company.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchAllCompanyForCombobox() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findAll())
        .then(response => {
          const result = response.data.body.content
          const options = []
          Object.keys(result).map(key => options.push({ value: result[key].id, text: result[key].name }))
          resolve(options)
        })
        .catch(error => reject(error))
    })
      .then(
        result => result,
        error => error,
      )
    return promise
  },

  async fetchWarehousesForCombobox() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findAllWarehouses())
        .then(response => {
          const result = response.data.body
          const options = []
          Object.keys(result).map(key => options.push({ value: result[key].id, text: result[key].name }))
          resolve(options)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async activate(id, status) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.company.Controller.activate(id, status))
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async activateAccountRequestEmail(reason) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.company.Controller.activateAccountRequestEmail(), reason)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async getCompanyMerchantConnectInfo() {
    const result = await axios.get(endpoints.company.Controller.getCompanyMerchantConnectInfo())
    return result.data.body
  },

  async createVendorByName(data) {
    const result = await axios.post(endpoints.company.Controller.createVendorByName(), data)
    return result.data.body
  },

  async updateWorkingDays(companyId, data) {
    const result = await axios.put(endpoints.company.Controller.updateWorkingDays(companyId), data)
    return result.data.body
  },

  async findAllTasksByQuery(data, pageable) {
    const result = await axios.post(endpoints.company.Controller.findAllTasksByQuery(pageable), data)
    return result.data.body
  },

  async findTaskById(taskId) {
    const result = await axios.get(endpoints.company.Controller.findTaskById(taskId))
    return result
  },

  async createTask(data) {
    const result = await axios.post(endpoints.company.Controller.createTask(), data)
    return result
  },

  async updateTask(data) {
    const result = await axios.put(endpoints.company.Controller.updateTask(), data)
    return result
  },

  async deleteTask(taskId) {
    const result = await axios.delete(endpoints.company.Controller.deleteTask(taskId))
    return result
  },

  async getWorkingDayCompanies(workingDay) {
    const result = await axios.get(endpoints.company.Controller.getWorkingDayCompanies(workingDay))
    return result.data.body
  },

  async getWorkingDaysForDashboard(companyId) {
    const result = await axios.get(endpoints.company.Controller.getWorkingDaysForDashboard(companyId))
    return result.data.body
  },

  async getCalendarPageQuery(data, pageable) {
    const result = await axios.post(endpoints.company.Controller.getCalendarPageQuery(pageable), data)
    return result.data.body
  },

  async generateTasks(data) {
    const result = await axios.post(endpoints.company.Controller.generateTasks(), data)
    return result
  },

  async getCalendarByCompanyId(companyId) {
    const result = await axios.get(endpoints.company.Controller.getCalendarByCompanyId(companyId))
    return result.data.body
  },

  async updateCompanyAverageMonthlyVolume(companyId, volume) {
    const result = await axios.get(endpoints.company.Controller.updateCompanyAverageMonthlyVolume(companyId, volume))
    return result
  },

  async saveCalendar(data) {
    const result = await axios.put(endpoints.company.Controller.saveCalendar(), data)
    return result
  },

  async upsertCalendar(companyId, data) {
    const result = await axios.put(endpoints.company.Controller.upsertCalendar(companyId), data)
    return result
  },

  async getTasksForDashboard() {
    const result = await axios.get(endpoints.company.Controller.getTasksForDashboard())
    return result.data.body
  },

  async queryTickets(data) {
    const result = await axios.post(endpoints.company.Controller.queryTickets(), data)
    return result.data.body
  },

  async findTicket(id) {
    const result = await axios.get(endpoints.company.Controller.findTicket(id))
    return result.data.body
  },

  async createTicket(data) {
    const result = await axios.post(endpoints.company.Controller.createTicket(), data)
    return result.data.body
  },

  async updateTicket(data) {
    const result = await axios.put(endpoints.company.Controller.updateTicket(), data)
    return result.data.body
  },

  async upsertTicket(data) {
    const result = await axios.put(endpoints.company.Controller.upsertTicket(), data)
    return result.data.body
  },

  async deleteTicket(id) {
    const result = await axios.delete(endpoints.company.Controller.deleteTicket(id))
    return result.data.body
  },

  async getIssueTypes() {
    const result = await axios.get(endpoints.company.Controller.getIssueTypes())
    return result.data.body
  },

  async getSupportStaff() {
    const result = await axios.get(endpoints.company.Controller.getSupportStaff())
    return result.data.body
  },
  async getIncomeExpenseCategoriesByQuery(pageable, data) {
    const result = await axios.post(endpoints.company.Controller.getIncomeExpenseCategoriesByQuery(pageable), data)
    return result.data.body
  },

  async getIncomeExpenseCategoryById(id) {
    const result = await axios.get(endpoints.company.Controller.getIncomeExpenseCategoryById(id))
    return result.data.body
  },

  async createIncomeExpenseCategory(data) {
    const result = await axios.post(endpoints.company.Controller.createIncomeExpenseCategory(), data)
    return result.data.body
  },

  async updateIncomeExpenseCategory(data) {
    const result = await axios.put(endpoints.company.Controller.updateIncomeExpenseCategory(), data)
    return result.data.body
  },

  async upsertIncomeExpenseCategory(data) {
    const result = await axios.put(endpoints.company.Controller.upsertIncomeExpenseCategory(), data)
    return result.data.body
  },

  async deleteIncomeExpenseCategory(id) {
    const result = await axios.delete(endpoints.company.Controller.deleteIncomeExpenseCategory(id))
    return result.data.body
  },
  async getExpensesByQuery(pageable, data) {
    const result = await axios.post(endpoints.company.Controller.getExpensesByQuery(pageable), data)
    return result.data.body
  },

  async getExpenseById(id) {
    const result = await axios.get(endpoints.company.Controller.getExpenseById(id))
    return result.data.body
  },

  async createExpense(data) {
    const result = await axios.post(endpoints.company.Controller.createExpense(), data)
    return result.data.body
  },

  async updateExpense(data) {
    const result = await axios.put(endpoints.company.Controller.updateExpense(), data)
    return result.data.body
  },

  async upsertExpense(data) {
    const result = await axios.put(endpoints.company.Controller.upsertExpense(), data)
    return result.data.body
  },

  async deleteExpense(id) {
    const result = await axios.delete(endpoints.company.Controller.deleteExpense(id))
    return result.data.body
  },
  async getIncomesByQuery(pageable, data) {
    const result = await axios.post(endpoints.company.Controller.getIncomesByQuery(pageable), data)
    return result.data.body
  },

  async getIncomeById(id) {
    const result = await axios.get(endpoints.company.Controller.getIncomeById(id))
    return result.data.body
  },

  async createIncome(data) {
    const result = await axios.post(endpoints.company.Controller.createIncome(), data)
    return result.data.body
  },

  async updateIncome(data) {
    const result = await axios.put(endpoints.company.Controller.updateIncome(), data)
    return result.data.body
  },

  async upsertIncome(data) {
    const result = await axios.put(endpoints.company.Controller.upsertIncome(), data)
    return result.data.body
  },

  async deleteIncome(id) {
    const result = await axios.delete(endpoints.company.Controller.deleteIncome(id))
    return result.data.body
  },
}
