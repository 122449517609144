/* eslint-disable no-param-reassign */
/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */
import { isDevelopment } from '@/libs/env.js'
import {
  onBeforeMount, computed, ref, watch,
} from '@vue/composition-api'
import { formatedDateStr } from '@/@core/utils/dateHelper.js'
import head from 'lodash/head'
import last from 'lodash/last'
import find from 'lodash/find'
import store from '@/store'
import printerAgentService from '@/http/requests/printer/printer-agent'
import { Notify } from '@robustshell/utils/index'
import { LabelParam } from '@/http/models/printer/entity/labelparam'
import pshBase64Const from './pshBase64Const'
import printerVarabiles, { vendors } from './printer-varaibles'

export default function usePrinter() {
  const currentPrinter = computed(() => store.getters['fbaShipment/getPrinterSettings'])

  const removeXMLInvalidChars = (string, removeDiscouragedChars = true) => {
    let regex = /((?:[\0-\x08\x0B\f\x0E-\x1F\uFFFD\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/g
    string = string.replace(regex, '')
    string = string.replace(/[^\w ]/g, '')
    if (removeDiscouragedChars) {
      regex = new RegExp(
        '([\\x7F-\\x84]|[\\x86-\\x9F]|[\\uFDD0-\\uFDEF]|(?:\\uD83F[\\uDFFE\\uDFFF])|(?:\\uD87F[\\uDF'
            + 'FE\\uDFFF])|(?:\\uD8BF[\\uDFFE\\uDFFF])|(?:\\uD8FF[\\uDFFE\\uDFFF])|(?:\\uD93F[\\uDFFE\\uD'
            + 'FFF])|(?:\\uD97F[\\uDFFE\\uDFFF])|(?:\\uD9BF[\\uDFFE\\uDFFF])|(?:\\uD9FF[\\uDFFE\\uDFFF])'
            + '|(?:\\uDA3F[\\uDFFE\\uDFFF])|(?:\\uDA7F[\\uDFFE\\uDFFF])|(?:\\uDABF[\\uDFFE\\uDFFF])|(?:\\'
            + 'uDAFF[\\uDFFE\\uDFFF])|(?:\\uDB3F[\\uDFFE\\uDFFF])|(?:\\uDB7F[\\uDFFE\\uDFFF])|(?:\\uDBBF'
            + '[\\uDFFE\\uDFFF])|(?:\\uDBFF[\\uDFFE\\uDFFF])(?:[\\0-\\t\\x0B\\f\\x0E-\\u2027\\u202A-\\uD7FF\\'
            + 'uE000-\\uFFFF]|[\\uD800-\\uDBFF][\\uDC00-\\uDFFF]|[\\uD800-\\uDBFF](?![\\uDC00-\\uDFFF])|'
            + '(?:[^\\uD800-\\uDBFF]|^)[\\uDC00-\\uDFFF]))', 'g',
      )
      string = string.replace(regex, '')
    }

    return string
  }
  const truncateTitle = text => {
    let title = text
    title = removeXMLInvalidChars(title)
    if (title && title.length > 90) {
      const lineCount = Math.ceil(title.length / 50)
      const newTitleArray = []
      for (let i = 0, o = 0; i < lineCount; i += 1, o += 50) {
        newTitleArray.push(title.substr(o, 50))
      }
      let headLine = head(newTitleArray)
      headLine = headLine.trimEnd()
      let lastLine = last(newTitleArray)
      lastLine = lastLine.trimStart()
      if (lastLine.length < 40) {
        lastLine = title.substr(title.length - 40)
      }

      title = `${headLine}...${lastLine}`
      return title
    }
    return title
  }
  const pshBase64 = ref(pshBase64Const)
  const printers = ref([])
  const labels = ref([])
  const selectedPrinter = ref({})

  async function getAvailablePrinters() {
    printerAgentService.availablePrinters().then(result => {
      printers.value.length = 0
      printers.value = result.data
    })
  }

  onBeforeMount(async () => {
    if (isDevelopment) return
    await getAvailablePrinters()
  })

  async function getLabels(vendorName) {
    const result = await printerAgentService.labelsByVendor(vendorName)
    labels.value.length = 0
    labels.value = result.data
  }

  async function getLabelIdByName(name) {
    if (labels.value.length === 0) await getLabels(currentPrinter.value.VendorName)
    const label = find(labels.value, p => p.Name === name)
    return label
  }

  watch(() => selectedPrinter,
    (newValue, oldValue) => {
      getLabels(newValue.value.VendorName)
    },
    { deep: true })

  async function print(params, labelName = '', count = 1) {
    const opt = {
      Vendor: currentPrinter.value.VendorName,
      Connection: currentPrinter.value.Address,
      LabelId: currentPrinter.value.LabelId,
      labelName: labelName,
      IterateCount: count,
      Params: params,
    }

    const result = await printerAgentService.printoutbylabel(opt)
    if (result.data === true) {
      Notify.success('Print out job sent')
    } else {
      Notify.error('Job failed')
    }
  }

  async function printStaticLabel(vendor, connection, labelName, params, count = 1) {
    const label = await getLabelIdByName(labelName)
    if (!Object.is(label, null) && !Object.is(label, undefined)) {
      const opt = {
        Vendor: vendor,
        Connection: connection,
        LabelId: label.Id,
        IterateCount: count,
        Params: params,
        LabelName: label.Name,
      }
      const result = await printerAgentService.printoutbylabel(opt)
      if (result.data === true) {
        Notify.success('Print out job sent')
      } else {
        Notify.error('Job failed')
      }
    } else {
      Notify.warning('No label template found.')
    }
  }

  async function printStaticLabelListHandle(vendor, connection, labelId, labelName, params, count = 1) {
    const opt = {
      Vendor: vendor,
      Connection: connection,
      LabelId: labelId,
      IterateCount: count,
      Params: params,
      LabelName: labelName,
    }
    const result = await printerAgentService.printoutbylabel(opt)
    if (result.data === true) {
      Notify.success('Print out job sent')
    } else {
      Notify.error('Job failed')
    }
  }

  async function printStaticLabelList(vendor, connection, labelName, listParams, count = 1) {
    const label = await getLabelIdByName(labelName)
    if (!Object.is(label, null) && !Object.is(label, undefined)) {
      const list = []
      listParams.forEach(params => {
        const opt = {
          Vendor: vendor,
          Connection: connection,
          LabelId: label.Id,
          LabelName: label.Name,
          IterateCount: count,
          Params: params,
        }
        list.push(opt)
      })

      try {
        const result = await printerAgentService.printOutByLabelList(list)
        if (result.data === true) {
          Notify.success('Print out job sent')
        } else {
          list.forEach(async item => {
            await printStaticLabelListHandle(item.Vendor, item.Connection, item.LabelId, item.LabelName, item.Params, item.IterateCount)
          })
        }
      } catch (error) {
        Notify.error('Error occurred while printing job')
      }
    } else {
      Notify.warning('No label template found.')
    }
  }

  async function printManualLabel(vendor, connection, labelId, params, count = 1) {
    const opt = {
      Vendor: vendor,
      Connection: connection,
      LabelId: labelId,
      IterateCount: count,
      Params: params,
    }

    const result = await printerAgentService.printoutbylabel(opt)
    if (result.data === true) {
      Notify.success('Print out job sent')
    } else {
      Notify.error('Job failed')
    }
  }

  async function getLabelDocument(vendor, labelName, params) {
    const label = await getLabelIdByName(labelName)
    const opt = {
      Vendor: vendor,
      LabelId: label.Id,
      Params: params,
    }
    const result = await printerAgentService.getLabelDocument(opt)
    return result.data
  }

  async function printStaticLabelCurrentPrinter(payload) {
    const label = await getLabelIdByName(payload.labelName)
    const opt = {
      Vendor: currentPrinter.value.VendorName,
      Connection: currentPrinter.value.Address,
      LabelId: label.Id,
      IterateCount: 1,
      Params: payload.params,
      LabelName: label.Name,
    }

    const result = await printerAgentService.printoutbylabel(opt)
    if (result.data === true) {
      Notify.success('Print out job sent')
    } else {
      Notify.error('Job failed')
    }
  }

  async function printFnskuLabel(payload) {
    const {
      barcode, title, expirationDate, copies,
    } = payload

    let expDate = ''
    if (expirationDate) {
      expDate = formatedDateStr(expirationDate, 'MM/DD/YYYY')
    }

    const params = []
    params.push(new LabelParam(printerVarabiles.BARCODE.value, barcode))
    params.push(new LabelParam(printerVarabiles.EXPRESSION_DATE.value, expDate))
    const titleStr = truncateTitle(title)
    const titleArr = titleStr.split(/(.{50})/).filter(p => p)
    if (titleArr.length > 1) {
      params.push(new LabelParam(printerVarabiles.TITLE_LINE1.value, titleArr[0]))
      params.push(new LabelParam(printerVarabiles.TITLE_LINE2.value, titleArr[1]))
    } else {
      params.push(new LabelParam(printerVarabiles.TITLE_LINE1.value, titleArr[0]))
      params.push(new LabelParam(printerVarabiles.TITLE_LINE2.value, ''))
    }
    await print(params, 'FNSKU', copies)
  }

  return {
    selectedPrinter,
    currentPrinter,
    printers,
    labels,
    pshBase64,
    getLabels,
    truncateTitle,
    printStaticLabel,
    printStaticLabelList,
    printStaticLabelCurrentPrinter,
    printManualLabel,
    printFnskuLabel,
    getLabelDocument,
  }
}
