<template>
  <dx-util-list
    id="lpnListId"
    ref="lpnListRef"
    height="100%"
    :items="dataSource"
    selection-mode="none"
    :bounce-enabled="true"
    :use-native-scrolling="true"
    :scroll-by-content="true"
    :scroll-by-thumb="true"
  >
    <template #item="{ data }">
      <div class="d-flex">
        <div class="col-1">
          <div class="h5 justify-content-start">
            <small> LPN </small>
          </div>
          <div class="justify-content-start mt-half">
            {{ data.lpn }}
          </div>
        </div>
        <div class="col-1">
          <div class="h5 text-muted justify-content-start">
            <small> Location </small>
          </div>
          <div class="text-dark justify-content-start mt-half">
            {{ data.locationName }}
          </div>
        </div>
        <div class="col-1">
          <div class="text-muted justify-content-start">
            <small> Created At </small>
          </div>
          <div class="h5 text-dark justify-content-start mt-half">
            {{ data.creationTime }}
          </div>
        </div>
      </div>
    </template>
  </dx-util-list>
</template>

<script>
import moment from 'moment'
import lpnService from '@/http/requests/warehouseLocation/location-lpn'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataSource: [],
    }
  },
  watch: {
    async componentId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        await this.getList()
      }
    },
  },
  async mounted() {
    if (!this.componentId && this.componentId.trim().length === 0) {
      await this.getList()
    }
  },
  methods: {
    async getList() {
      const result = await lpnService.fetchAll()
      this.dataSource.length = 0
      const list = result.map(item => ({
        creationTime: moment(item.creationTime).format('YYYY-MM-DD HH:mm:ss'),
        lpn: item.lpn,
        locationName: item.locationName,
      }))
      this.dataSource.push(...list)
    },
  },
}
</script>

<style></style>
