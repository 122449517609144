import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchAll() {
    const result = await axios.get(endpoints.warehouseLocation.LpnController.getList())
    return result.data.body
  },
  async fetchByLpn(lpn) {
    const result = await axios.get(endpoints.warehouseLocation.LpnController.getByLpn(lpn))
    return result.data.body
  },
  async fetchByLocation(location) {
    const result = await axios.get(endpoints.warehouseLocation.LpnController.getByLocation(location))
    return result.data.body
  },
  async setLocation(id, location) {
    const result = await axios.put(endpoints.warehouseLocation.LpnController.setLocation(id, location))
    return result
  },
  async create(data) {
    const result = await axios.post(endpoints.warehouseLocation.LpnController.create(), data)
    return result
  },

  async delete(id) {
    const result = await axios.delete(endpoints.warehouseLocation.LpnController.delete(id))
    return result
  },
}
