/* eslint-disable no-unused-vars */
import { ref, computed } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'
import { TenantTypeEnum } from '@/enums'
import CompanyTypeEnum from '@/enums/companyType.enum'

export default function useCurrentUser() {
  const currentUser = getUserData()

  const {
    id,
    ability,
    fullname,
    username,
    accountNo,
    suiteNumber,
    companyId,
    companyType,
    company,
    tenantId,
    tenantType,
    warehouse,
    membershipInfo,
    role,
  } = currentUser

  const userId = ref(id)
  const userAbility = ref(ability)
  const userFullname = ref(fullname)
  const userUsername = ref(username)
  const userAccountNo = ref(accountNo)
  const userSuiteNumber = ref(suiteNumber)
  const userCompanyId = ref(companyId)
  const userCompanyType = ref(companyType)
  const userTenantId = ref(tenantId)
  const userTenantType = ref(tenantType)
  const userCompanyName = ref(company)
  const userRoleName = ref(role.roleName)

  const warehouseId = (warehouse && warehouse.id) ? warehouse.id : 0
  const warehouseName = (warehouse && warehouse.name) ? warehouse.name : ''
  const userWarehouseId = ref(warehouseId)
  const userWarehouseName = ref(warehouseName)

  const plans = membershipInfo?.modulesState ?? {}
  const planStates = ref(Object.keys(plans).map(key => ({
    planId: key,
    ...plans[key],
  })) ?? [])
  const remainingTrialDays = computed(() => planStates.value
    .filter(el => el.state === 'TRIAL')
    .sort((a, b) => a.trialRemaining - b.trialRemaining) ?? [])

  const duePlans = computed(() => planStates.value.filter(el => el.state === 'DUE_FAIL'))
  const dueTrialPlans = computed(() => planStates.value.filter(el => el.state === 'DUE_NOT_TRIAL'))
  const duePlanExists = computed(() => duePlans.value.length > 0)
  const dueTrialPlanExists = computed(() => dueTrialPlans.value.length > 0)
  const subscribedMembershipPlans = planStates.value
  const isServingTenant = computed(() => userTenantType.value === TenantTypeEnum.SERVING.value)
  const isIndividualTenant = computed(() => userTenantType.value === TenantTypeEnum.INDIVIDUAL.value)

  // Check if the user is a warehouse type company
  const isCompanyTypeWarehouse = computed(() => userCompanyType.value === CompanyTypeEnum.Warehouse.value)

  return {
    userId,
    userAbility,
    userFullname,
    userUsername,
    userAccountNo,
    userSuiteNumber,
    userCompanyId,
    userCompanyType,
    userCompanyName,
    userTenantId,
    userTenantType,
    userWarehouseId,
    userWarehouseName,
    userRoleName,
    duePlans,
    duePlanExists,
    dueTrialPlans,
    dueTrialPlanExists,
    remainingTrialDays,
    subscribedMembershipPlans,
    isServingTenant,
    isIndividualTenant,
    isCompanyTypeWarehouse,
  }
}
