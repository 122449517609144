/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars

import toUpper from 'lodash/toUpper'

function isRequired(value) {
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    return true
  }
  return false
}

function isNumeric(value) {
  return /^-?\d+$/.test(value)
}

function isExist(value, data) {
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    const condition = toUpper(value)
    const result = data.find(item => item === condition)
    if (result !== '' && result !== null && typeof result !== 'undefined') {
      const check = result.localeCompare(result, undefined, { sensitivity: 'variant' })
      if (check === 0) return true
    }
  }
  return false
}

function isNumber(value) {
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    const newVal = parseInt(value, 10)
    if (typeof newVal === 'number' && newVal > 0) {
      return true
    }
  }
  return false
}

function isRequiredAny(value, value1) {
  if ((value !== '' && value !== null && typeof value !== 'undefined')
        || (value1 !== '' && value1 !== null && typeof value1 !== 'undefined')) {
    return true
  }
  return false
}

function isValidAsin(value) {
  if (!value || value === '') {
    return false
  }
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    value = value.toString().toUpperCase()
    const regex = /^B[A-Z0-9]{9}$/gm
    const isbnRegex = /^[0-9]{10}$/gm
    const isbnxRegex = /^[0-9]{9}X$/gm
    if (regex.test(value) || isbnRegex.test(value) || isbnxRegex.test(value)) {
      return true
    }
    return false
  }
  return true
}

function isValidFnsku(value) {
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    const regex = /^X[A-Z0-9]{9}$/gm
    return regex.test(value)
  }
  return true
}

function isValidFnskuSameAsin(value, asin) {
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    const regex = /^X[A-Z0-9]{9}$/gm
    const result = regex.test(value)
    if (!result && asin !== '' && asin !== null && typeof asin !== 'undefined' && isValidAsin(asin) && value === asin) {
      return true
    }
    return result
  }
  return true
}

function isValidMsku(value) {
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    const regex = /^-?\d+$/.test(value)
    if (regex) {
      return value.toString().length <= 40
    }
    return value.length <= 40
  }
  return true
}

function isValidUpc(value) {
  if (!value || value === '') {
    return false
  }
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    const regex = /^[0-9]{12,13}$/gm
    return regex.test(value)
  }
  return true
}

function isValidScanedUpc(value) {
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    const regex = /^[0-9]{12,13}$/gm
    return regex.test(value)
  }
  return false
}

function isCountNumberOfUnits(bundle, multiPack, numberOfUnits) {
  if ((bundle !== '' && bundle !== null && typeof bundle !== 'undefined')
      && (multiPack !== '' && multiPack !== null && typeof multiPack !== 'undefined')) {
    const bundleCompare = bundle.localeCompare('YES', undefined, { sensitivity: 'base' })
    const multiPackCompare = multiPack.localeCompare('YES', undefined, { sensitivity: 'base' })
    if (bundleCompare !== 0 && multiPackCompare !== 0) return true

    if (numberOfUnits !== '' && numberOfUnits !== null && typeof numberOfUnits !== 'undefined') {
      const numeric = isNumeric(numberOfUnits)
      if ((bundleCompare === 0 || multiPackCompare === 0) && numeric && numberOfUnits > 0) {
        return true
      }
    }
  }
  return false
}

function isCountPerBundle(bundle, multiPack, countPerBundle) {
  if ((bundle !== '' && bundle !== null && typeof bundle !== 'undefined')
      && (multiPack !== '' && multiPack !== null && typeof multiPack !== 'undefined')) {
    const bundleCompare = bundle.localeCompare('YES', undefined, { sensitivity: 'base' })
    const multiPackCompare = multiPack.localeCompare('YES', undefined, { sensitivity: 'base' })
    if (bundleCompare !== 0 && multiPackCompare !== 0) return true

    if (countPerBundle !== '' && countPerBundle !== null && typeof countPerBundle !== 'undefined') {
      const numeric = isNumeric(countPerBundle)
      if ((bundleCompare === 0 || multiPackCompare === 0) && numeric && countPerBundle >= 2) {
        return true
      }
    }
  }
  return false
}

function isCountWheNoBundleOrMultipack(bundle, multiPack, countPerBundle) {
  if ((bundle !== '' && bundle !== null && typeof bundle !== 'undefined')
      && (multiPack !== '' && multiPack !== null && typeof multiPack !== 'undefined')) {
    const bundleCompare = bundle.localeCompare('NO', undefined, { sensitivity: 'base' })
    const multiPackCompare = multiPack.localeCompare('NO', undefined, { sensitivity: 'base' })
    if (bundleCompare === 0 && multiPackCompare === 0 && countPerBundle > 0) return false
  }
  return true
}

function isBundleOrMultipack(bundle, multiPack) {
  if ((bundle !== '' && bundle !== null && typeof bundle !== 'undefined')
      && (multiPack !== '' && multiPack !== null && typeof multiPack !== 'undefined')) {
    const bundleCompare = bundle.localeCompare('YES', undefined, { sensitivity: 'base' })
    const multiPackCompare = multiPack.localeCompare('YES', undefined, { sensitivity: 'base' })
    if (bundleCompare !== 0 && multiPackCompare !== 0) return true

    if (bundleCompare === 0 && multiPackCompare === 0) {
      return false
    }
    return true
  }
  return false
}

function isCurrency(value) {
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    const floatVal = parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/gm, '$1,')
    const regex = new RegExp(/^((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:\,\d{3})?)*((?=.*\d)(?:\.\d\d){1}?){0,1}$/gm)
    return regex.test(floatVal)
  }
  return false
}
function isLessThan(value, value1) {
  if ((value !== '' && value !== null && typeof value !== 'undefined')
  && (value1 !== '' && value1 !== null && typeof value1 !== 'undefined')) {
    const newVal = parseInt(value, 10)
    const newVal1 = parseInt(value1, 10)
    if (typeof newVal === 'number' && newVal > 0 && typeof newVal1 === 'number' && newVal1 > 0) {
      return newVal > newVal1
    }
  }
  return true
}

function isOptionalButNotEmpty(value) {
  if (!value || value === '') {
    return false // if empty, we return true, there is no problem
  }
  return true
}

function isValidEmail(value) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return emailRegex.test(value)
}

export {
  isNumber,
  isRequired,
  isExist,
  isRequiredAny,
  isValidFnsku,
  isValidFnskuSameAsin,
  isValidMsku,
  isValidAsin,
  isValidUpc,
  isValidScanedUpc,
  isCountNumberOfUnits,
  isCountPerBundle,
  isCountWheNoBundleOrMultipack,
  isCurrency,
  isLessThan,
  isNumeric,
  isBundleOrMultipack,
  isValidEmail,
  isOptionalButNotEmpty,
}
