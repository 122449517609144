import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import sortItemService from '@/http/requests/inbound/sort-item.service'
import Pager from '@core/dev-extreme/utils/pager'
import CatalogItem from '@/libs/app/catalog-item'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)

    const pageableQuery = `${pager.toCreatePageable()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await sortItemService.fetchItemsByQuery(filters, pageableQuery)
    const data = response.data.body
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
  insert: async values => sortItemService.create(values),
  update: async (type, payload) => {
    if (type === 'quantity') {
      const data = {
        id: payload.id,
        quantity: payload.quantity,
      }
      await sortItemService.updateQuantity(data)
    } else {
      const data = {
        id: payload.id,
        orderNumber: payload.orderNumber,
      }
      await sortItemService.updateOrderNumber(data)
    }
  },
  remove: key => {
    sortItemService.delete(key)
  },
})

const ItemSortDataSource = new DataSource({
  store: store,
  map: dataItem => {
    const item = { ...dataItem }
    const catalogItemIns = new CatalogItem()
    catalogItemIns.catalogItem = item.catalogItem
    item.productTitle = catalogItemIns.getTitle()
    return item
  },
})

export {
  ItemSortDataSource,
}
