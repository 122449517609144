import find from 'lodash/find'
/**
 * Enum for Sorted Item Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const itemSortStatusEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'Status (All)' },
  OPEN: { key: 'OPEN', value: 'OPEN', text: 'Open' },
  MOVED: { key: 'MOVED', value: 'MOVED', text: 'Moved' },
})

function getItemSortStatusEnum(predicate, predicateKey = 'key') {
  const result = find(itemSortStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getItemSortStatusEnumList() {
  const options = []
  Object.keys(itemSortStatusEnum)
    .map(key => options.push(
      {
        value: itemSortStatusEnum[key].value,
        text: itemSortStatusEnum[key].text,
      },
    ))
  return options
}

export default itemSortStatusEnum

export {
  getItemSortStatusEnum,
  getItemSortStatusEnumList,
}
