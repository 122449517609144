import find from 'lodash/find'
/**
 * Enum for Amazon Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const vendors = Object.freeze({
  Rollo: { value: 'Rollo' },
})

const labelTypes = Object.freeze({
  BOX_NAME: { key: 'BoxName', value: 'BoxName' },
  FNSKU: { key: 'FNSKU', value: 'FNSKU' },
  CONTENT_BARCODE_LABEL: { key: 'ContentBarcodeLabel', value: 'ContentBarcodeLabel' },
  PALLET_BARCODE_LABEL: { key: 'PalletBarcodeLabel', value: 'PalletBarcodeLabel' },
})

const printerVarabiles = Object.freeze({
  BOX_NAME: { key: 'boxName', value: '%%boxName%%', label: 'Box' },
  BARCODE: { key: 'barcode', value: '%%barcode%%', label: 'FNSKU' },
  EXPRESSION_DATE: { key: 'expressionDate', value: '%%expressionDate%%', label: 'FNSKU' },
  TITLE_LINE1: { key: 'titleLine1', value: '%%titleLine1%%', label: 'FNSKU' },
  TITLE_LINE2: { key: 'titleLine2', value: '%%titleLine2%%', label: 'FNSKU' },
  SHIP_FROM_LINE1: { key: 'shipFromLine1', value: '%%shipFromLine1%%', label: 'ContentBarcodeLabel' },
  SHIP_FROM_LINE2: { key: 'shipFromLine2', value: '%%shipFromLine2%%', label: 'ContentBarcodeLabel' },
  SHIP_FROM_LINE3: { key: 'shipFromLine3', value: '%%shipFromLine3%%', label: 'ContentBarcodeLabel' },
  SHIP_FROM_LINE4: { key: 'shipFromLine4', value: '%%shipFromLine4%%', label: 'ContentBarcodeLabel' },
  SHIP_FROM_LINE5: { key: 'shipFromLine5', value: '%%shipFromLine5%%', label: 'ContentBarcodeLabel' },
  SHIP_FROM_LINE6: { key: 'shipFromLine6', value: '%%shipFromLine6%%', label: 'ContentBarcodeLabel' },
  SHIP_TO_LINE1: { key: 'shipToLine1', value: '%%shipToLine1%%', label: 'ContentBarcodeLabel' },
  SHIP_TO_LINE2: { key: 'shipToLine2', value: '%%shipToLine2%%', label: 'ContentBarcodeLabel' },
  SHIP_TO_LINE3: { key: 'shipToLine3', value: '%%shipToLine3%%', label: 'ContentBarcodeLabel' },
  SHIP_TO_LINE4: { key: 'shipToLine4', value: '%%shipToLine4%%', label: 'ContentBarcodeLabel' },
  SHIP_TO_LINE5: { key: 'shipToLine5', value: '%%shipToLine5%%', label: 'ContentBarcodeLabel' },
  SHIP_TO_LINE6: { key: 'shipToLine6', value: '%%shipToLine6%%', label: 'ContentBarcodeLabel' },
  SHIPPING_PLAN_NAME: { key: 'shippingPlanName', value: '%%shippingPlanName%%', label: 'ContentBarcodeLabel' },
  CREATED_AT: { key: 'createdAt', value: '%%createdAt%%', label: 'ContentBarcodeLabel' },
  BATCH_AND_BOX_NAME: { key: 'batchAndBoxName', value: '%%batchAndBoxName%%', label: 'ContentBarcodeLabel' },
  ITEM_QTY: { key: 'itemQty', value: '%%itemQty%%', label: 'ContentBarcodeLabel' },
  SKU_QTY: { key: 'skuQty', value: '%%skuQty%%', label: 'ContentBarcodeLabel' },
  BARCODE_CONTENT: { key: 'barcode', value: '%%barcode%%', label: 'ContentBarcodeLabel' },
  CURRENT_DATE: { key: 'currentDate', value: '%%currentDate%%', label: 'ContentBarcodeLabel' },
  PSH_BASE64_CONST: { key: 'pshBase64Const', value: '%%pshBase64Const%%', label: 'ContentBarcodeLabel' },
})

const palletBarcodeVarabiles = Object.freeze({
  SHIP_FROM_LINE1: { key: 'shipFromLine1', value: '%%shipFromLine1%%', label: 'PalletBarcodeLabel' },
  SHIP_FROM_LINE2: { key: 'shipFromLine2', value: '%%shipFromLine2%%', label: 'PalletBarcodeLabel' },
  SHIP_FROM_LINE3: { key: 'shipFromLine3', value: '%%shipFromLine3%%', label: 'PalletBarcodeLabel' },
  SHIP_FROM_LINE4: { key: 'shipFromLine4', value: '%%shipFromLine4%%', label: 'PalletBarcodeLabel' },
  SHIP_FROM_LINE5: { key: 'shipFromLine5', value: '%%shipFromLine5%%', label: 'PalletBarcodeLabel' },
  SHIP_FROM_LINE6: { key: 'shipFromLine6', value: '%%shipFromLine6%%', label: 'PalletBarcodeLabel' },
  SHIP_TO_LINE1: { key: 'shipToLine1', value: '%%shipToLine1%%', label: 'PalletBarcodeLabel' },
  SHIP_TO_LINE2: { key: 'shipToLine2', value: '%%shipToLine2%%', label: 'PalletBarcodeLabel' },
  SHIP_TO_LINE3: { key: 'shipToLine3', value: '%%shipToLine3%%', label: 'PalletBarcodeLabel' },
  SHIP_TO_LINE4: { key: 'shipToLine4', value: '%%shipToLine4%%', label: 'PalletBarcodeLabel' },
  SHIP_TO_LINE5: { key: 'shipToLine5', value: '%%shipToLine5%%', label: 'PalletBarcodeLabel' },
  SHIP_TO_LINE6: { key: 'shipToLine6', value: '%%shipToLine6%%', label: 'PalletBarcodeLabel' },
  SHIPPING_PLAN_NAME: { key: 'shippingPlanName', value: '%%shippingPlanName%%', label: 'PalletBarcodeLabel' },
  CREATED_AT: { key: 'createdAt', value: '%%createdAt%%', label: 'PalletBarcodeLabel' },
  BATCH_AND_BOX_NAME: { key: 'batchAndBoxName', value: '%%batchAndBoxName%%', label: 'PalletBarcodeLabel' },
  CURRENT_DATE: { key: 'currentDate', value: '%%currentDate%%', label: 'PalletBarcodeLabel' },
  PSH_BASE64_CONST: { key: 'pshBase64Const', value: '%%pshBase64Const%%', label: 'PalletBarcodeLabel' },
})

function getPrinterVarabile(predicate, predicateKey = 'key') {
  const result = find(printerVarabiles, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getPrinterVarabiles() {
  const result = Object.keys(printerVarabiles).map(key => printerVarabiles[key])
  return result
}

export default printerVarabiles

export {
  vendors,
  palletBarcodeVarabiles,
  labelTypes,
  getPrinterVarabile,
  getPrinterVarabiles,
}
