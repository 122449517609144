/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class PlanSearchFilter extends BaseSearchFilter {
  /** @type {?number} */
  #id = 0
  /** @returns {?number} */
  get id() {
    return this.#id
  }
  /** @param {?number} value */
  set id(value) {
    this.#id = value
  }

  /** @type {?number} */
  #planType = 0
  /** @returns {?number} */
  get planType() {
    return this.#planType
  }
  /** @param {?number} value */
  set planType(value) {
    this.#planType = value
  }

  /** @type {!string} */
  #planCategory = 'all'
  /** @returns {string} */
  get planCategory() {
    return this.#planCategory
  }
  /** @param {!string} value */
  set planCategory(value) {
    this.#planCategory = value
  }

  /** @type {!string} */
  #status = 'active'
  /** @returns {string} */
  get status() {
    return this.#status
  }
  /** @param {!string} value */
  set status(value) {
    this.#status = value
  }

  /** @type {!string} */
  #visibility = 'reserved_all'
  /** @returns {string} */
  get visibility() {
    return this.#visibility
  }
  /** @param {!string} value */
  set visibility(value) {
    this.#visibility = value
  }
  constructor() {
    super()
    this.#id = this.id
    this.#planType = this.planType
    this.#planCategory = this.planCategory
    this.#status = this.status
    this.#visibility = this.visibility
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.id = 0
    this.planType = 0
    this.planCategory = 'all'
    this.status = 'active'
    this.visibility = 'reserved_all'
  }

  /**
   * The complete PlanSearchFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {number} id - Id.
   * @property {string} planType - Plan Type.
   * @property {string} planCategory - Plan Category.
   * @property {string} status - Status.
   * @property {string} visibility - Visibility.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      id: this.id,
      planType: this.planType,
      planCategory: this.planCategory,
      status: this.status,
      visibility: this.visibility,
    }
    return filter
  }
}
export default new PlanSearchFilter()
