import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const itemActionEnum = Object.freeze({
  ALL: { key: 'all', value: 'ALL', text: 'Action (All)' },
  FBA: { key: 'fba', value: 'FBA', text: 'FBA' },
  FBM: { key: 'fbm', value: 'FBM', text: 'FBM' },
  RETURN: { key: 'return', value: 'RETURN', text: 'Return' },
  REMOVAL_ORDER: { key: 'removal_order', value: 'REMOVAL_ORDER', text: 'Removal Order' },
  FORWARDING: { key: 'forwarding', value: 'FORWARDING', text: 'Forwarding' },
  STORAGE: { key: 'storage', value: 'STORAGE', text: 'Storage' },
  DROPSHIP: { key: 'dropship', value: 'DROPSHIP', text: 'Dropship' },
  B2B: { key: 'b2b', value: 'B2B', text: 'B2B' },
  WFS: { key: 'wfs', value: 'WFS', text: 'WFS' },
})

function getItemActionEnum(predicate, predicateKey = 'key') {
  const result = find(itemActionEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getItemActionEnumList() {
  const options = []
  Object
    .keys(itemActionEnum)
    .map(key => options.push(
      {
        value: itemActionEnum[key].value,
        text: itemActionEnum[key].text,
      },
    ))
  return options
}

export default itemActionEnum

export {
  getItemActionEnum,
  getItemActionEnumList,
}
