import endpoints from '@/http/endpoints'
import axios from 'axios'

const baseURL = process.env.VUE_APP_PRINTERAPI_URL
const timeout = process.env.VUE_APP_TIMEOUT

const accessToken = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiIiLCJleHAiOjE2NjgyNjQyNjl9.to9nY1FTak-N7MnJrst6EN0hQpvwX_vehD0U0EfGX0nWZ7GsnpflCuiRgMouUCfbwxCfZbquWUv5feUaubmP5A'
const bearer = `Bearer ${accessToken}`

axios.defaults.trailingSlash = false
const axiosPrinter = axios.create({
  baseURL,
  timeout,
  headers: {
    'Content-type': 'application/json;charset=UTF-8',
    'Accept-Language': 'en',
    get: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    post: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
    common: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: bearer,
    },
  },
})
export default {

  async isAgentInstalled() {
    const promise = new Promise((resolve, reject) => {
      axiosPrinter.get(endpoints.printerAgentManagement.Controller.availablePrinters())
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async availablePrinters() {
    const promise = new Promise((resolve, reject) => {
      axiosPrinter.get(endpoints.printerAgentManagement.Controller.availablePrinters())
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async labelTypes(vendor, printermodel) {
    const promise = new Promise((resolve, reject) => {
      axiosPrinter.get(endpoints.printerAgentManagement.Controller.labelTypes(), { headers: { vendor: vendor, printermodel: printermodel } })
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async labels(printermodel) {
    const promise = new Promise((resolve, reject) => {
      axiosPrinter.get(endpoints.printerAgentManagement.Controller.labels(), { headers: { printermodel: printermodel } })
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getAgentVersion() {
    const result = await axiosPrinter.get(endpoints.printerAgentManagement.Controller.getAgentVersion())
    return result.data
  },
  async printoutbylabel(data) {
    const result = await axiosPrinter.post(endpoints.printerAgentManagement.Controller.printoutbylabel(), data)
    return { data: result.data }
  },
  async printOutByLabelList(data) {
    const result = await axiosPrinter.post(endpoints.printerAgentManagement.Controller.printOutByLabelList(), data)
    return { data: result.data }
  },
  async getLabelDocument(data) {
    const result = await axiosPrinter.post(endpoints.printerAgentManagement.Controller.getLabelDocument(), data)
    return { data: result.data }
  },
  async printoutbyraw(data) {
    const promise = new Promise((resolve, reject) => {
      axiosPrinter.post(endpoints.printerAgentManagement.Controller.printoutbyrawdata(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async labelsByVendor(vendor) {
    const response = await axiosPrinter.get(endpoints.printerAgentManagement.Controller.labelsbyvendor(), { headers: { vendor: vendor } })
    return {
      data: response.data,
    }
  },
}
