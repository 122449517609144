import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import isUndefined from 'lodash/isUndefined'
import gt from 'lodash/gt'
import isNull from 'lodash/isNull'
import head from 'lodash/head'
import find from 'lodash/find'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

class CatalogItem {
  /** @type {object} */
  #catalogItem = {}

  /** @type {array} */
  #priceList = []

  constructor() {
    this.#catalogItem = this.catalogItem
    this.#priceList = this.priceList
  }

  /** @returns {object} */
  get catalogItem() {
    return this.#catalogItem
  }

  /** @param {object} value */
  set catalogItem(value) {
    if (isUndefined(value) || isNull(value) || isEmpty(value)) {
      Object.assign(this.#catalogItem, {})
    } else {
      this.#catalogItem = { ...value }
    }
  }

  get priceList() {
    return this.#priceList
  }

  /** @param {object} value */
  set priceList(value) {
    if (isUndefined(value) || isNull(value) || isEmpty(value) || !gt(value.length, 0)) {
      this.#priceList = []
    } else {
      this.#priceList = [...value]
    }
  }

  getSummary() {
    const catalogItem = this.catalogItem
    const summary = {
      brandName: '',
      colorName: '',
      itemName: '',
      manufacturer: '',
      modelNumber: '',
      sizeName: '',
      styleName: '',
    }
    if (
      !isEmpty(catalogItem.summaries)
      && !isUndefined(catalogItem.summaries)
      && isArray(catalogItem.summaries)
      && gt(catalogItem.summaries.length, 0)) {
      const item = head(catalogItem.summaries)
      summary.brandName = !isUndefined(item.brandName) ? item.brandName : ''
      summary.colorName = !isUndefined(item.colorName) ? item.colorName : ''
      summary.itemName = !isUndefined(item.itemName) ? item.itemName : ''
      summary.manufacturer = !isUndefined(item.manufacturer) ? item.manufacturer : ''
      summary.modelNumber = !isUndefined(item.modelNumber) ? item.modelNumber : ''
      summary.sizeName = !isUndefined(item.sizeName) ? item.sizeName : ''
      summary.styleName = !isUndefined(item.styleName) ? item.styleName : ''
    }
    return summary
  }

  getAsin() {
    const catalogItem = this.catalogItem
    if (!isEmpty(catalogItem) && !isUndefined(catalogItem.asin)) {
      return catalogItem.asin
    }
    return ''
  }

  // eslint-disable-next-line class-methods-use-this
  getDefaultImageLink() {
    return defaultImageUrl
  }

  getImageLink() {
    const catalogItem = this.catalogItem
    let imageUrl = defaultImageUrl
    if (!isEmpty(catalogItem)) {
      if (
        !isEmpty(catalogItem.images)
        && !isUndefined(catalogItem.images)
        && isArray(catalogItem.images)
        && gt(catalogItem.images.length, 0)
      ) {
        let image = head(catalogItem.images)
        if (
          !isEmpty(image)
          && !isUndefined(image.images)
          && isArray(image.images)
          && gt(image.images.length, 0)) {
          image = head(image.images)
          if (!isUndefined(image.link)) {
            imageUrl = image.link
          }
        }
      }
    }
    return imageUrl
  }

  getTitle() {
    const catalogItem = this.catalogItem
    let title = ''
    if (
      !isEmpty(catalogItem)
      && !isUndefined(catalogItem.summaries)
      && isArray(catalogItem.summaries)
      && gt(catalogItem.summaries.length, 0)) {
      const summary = head(catalogItem.summaries)
      if (!isUndefined(summary.itemName)) {
        title = summary.itemName
      }
    }
    return title
  }

  catalogItemIns() {
    const catalogItem = this.catalogItem
    const summary = {
      brandName: '',
      colorName: '',
      itemName: '',
      manufacturer: '',
      modelNumber: '',
      sizeName: '',
      styleName: '',
    }
    if (
      !isEmpty(catalogItem)
      && !isUndefined(catalogItem.summaries)
      && isArray(catalogItem.summaries)
      && gt(catalogItem.summaries.length, 0)) {
      const item = head(catalogItem.summaries)
      summary.brandName = !isUndefined(item.brandName) ? item.brandName : ''
      summary.colorName = !isUndefined(item.colorName) ? item.colorName : ''
      summary.itemName = !isUndefined(item.itemName) ? item.itemName : ''
      summary.manufacturer = !isUndefined(item.manufacturer) ? item.manufacturer : ''
      summary.modelNumber = !isUndefined(item.modelNumber) ? item.modelNumber : ''
      summary.sizeName = !isUndefined(item.sizeName) ? item.sizeName : ''
      summary.styleName = !isUndefined(item.styleName) ? item.styleName : ''
    }
    return summary
  }

  getBrand() {
    const catalogItem = this.catalogItem
    let brand = ''
    if (
      !isEmpty(catalogItem)
      && !isUndefined(catalogItem.summaries)
      && isArray(catalogItem.summaries)
      && gt(catalogItem.summaries.length, 0)) {
      const summary = head(catalogItem.summaries)
      if (!isUndefined(summary.brandName)) {
        brand = summary.brandName
      }
    }
    return brand
  }

  getSizeName() {
    const catalogItem = this.catalogItem
    let sizeName = ''
    if (
      !isEmpty(catalogItem)
      && !isUndefined(catalogItem.summaries)
      && isArray(catalogItem.summaries)
      && gt(catalogItem.summaries.length, 0)) {
      const summary = head(catalogItem.summaries)
      if (!isUndefined(summary.sizeName)) {
        sizeName = summary.sizeName
      }
    }
    return sizeName
  }

  getProductType() {
    const catalogItem = this.catalogItem
    let type = 'PRODUCT'
    if (
      !isEmpty(catalogItem)
      && !isUndefined(catalogItem.productTypes)
      && isArray(catalogItem.productTypes)
      && gt(catalogItem.productTypes.length, 0)) {
      const productType = head(catalogItem.productTypes)
      if (!isUndefined(productType.productType)) {
        type = productType.productType
      }
    }
    return type
  }

  getSalesRank() {
    let rank = null
    const catalogItem = this.catalogItem
    if (
      !isEmpty(catalogItem)
      && !isUndefined(catalogItem.salesRanks)
      && isArray(catalogItem.salesRanks)
      && gt(catalogItem.salesRanks.length, 0)) {
      const salesRanks = head(catalogItem.salesRanks)
      if (!isUndefined(salesRanks.displayGroupRanks) && isArray(salesRanks.displayGroupRanks) && gt(salesRanks.displayGroupRanks.length, 0)) {
        const salesRank = head(salesRanks.displayGroupRanks)
        rank = `${salesRank.title} (${salesRank.rank})`
      }
      if (!isUndefined(salesRanks.classificationRanks) && isArray(salesRanks.classificationRanks) && gt(salesRanks.classificationRanks.length, 0)) {
        const salesRank = head(salesRanks.classificationRanks)
        rank = `${rank} / ${salesRank.title} (${salesRank.rank})`
      }
    }
    return rank
  }

  getRank() {
    let rank = null
    const priceList = [...this.priceList]
    if (
      !isEmpty(priceList)
      && isArray(priceList)
      && gt(priceList.length, 0)) {
      const rankHead = head(priceList)
      if (!isEmpty(rankHead) && !isUndefined(rankHead.product)) {
        if (
          !isEmpty(rankHead.product.salesRankings)
          && !isUndefined(rankHead.product.salesRankings)
          && isArray(rankHead.product.salesRankings)
          && gt(rankHead.product.salesRankings.length, 0)) {
          const saleRank = head(rankHead.product.salesRankings)
          if (!isUndefined(saleRank.rank)) {
            rank = saleRank.rank
          }
        }
      }
    }
    return rank
  }
}

function itemRank(salesRanks) {
  let rank = null
  if (
    !isEmpty(salesRanks)
    && isArray(salesRanks)
    && gt(salesRanks.length, 0)) {
    const rankHead = head(salesRanks)
    if (!isEmpty(rankHead) && !isUndefined(rankHead.product)) {
      if (
        !isEmpty(rankHead.product.salesRankings)
        && !isUndefined(rankHead.product.salesRankings)
        && isArray(rankHead.product.salesRankings)
        && gt(rankHead.product.salesRankings.length, 0)) {
        const saleRank = head(rankHead.product.salesRankings)
        if (!isUndefined(saleRank.rank)) {
          rank = saleRank.rank
        }
      }
    }
  }
  return rank
}

function itemRank1(data) {
  let rank = null
  if (!isEmpty(data)) {
    const salesRanks = head(data)
    if (!isUndefined(salesRanks.displayGroupRanks) && isArray(salesRanks.displayGroupRanks) && gt(salesRanks.displayGroupRanks.length, 0)) {
      const salesRank = head(salesRanks.displayGroupRanks)
      rank = `${salesRank.title} (${salesRank.rank})`
    }
    if (!isUndefined(salesRanks.classificationRanks) && isArray(salesRanks.classificationRanks) && gt(salesRanks.classificationRanks.length, 0)) {
      const salesRank = head(salesRanks.classificationRanks)
      rank = `${rank} / ${salesRank.title} (${salesRank.rank})`
    }
  }
  return rank
}

function itemImageLink(images) {
  let imageUrl = defaultImageUrl
  if (
    !isEmpty(images)
    && !isUndefined(images)
    && isArray(images)
    && gt(images.length, 0)
  ) {
    let image = head(images)
    if (
      !isEmpty(image)
      && !isUndefined(image.images)
      && isArray(image.images)
      && gt(image.images.length, 0)) {
      image = head(image.images)
      if (!isUndefined(image.link)) {
        imageUrl = image.link
      }
    }
  }
  return imageUrl
}

function itemSummary(summaries) {
  const summary = {
    brandName: '',
    colorName: '',
    itemName: '',
    manufacturer: '',
    modelNumber: '',
    sizeName: '',
    styleName: '',
  }
  if (
    !isEmpty(summaries)
    && !isUndefined(summaries)
    && isArray(summaries)
    && gt(summaries.length, 0)) {
    const item = head(summaries)
    summary.brandName = !isUndefined(item.brandName) ? item.brandName : ''
    summary.colorName = !isUndefined(item.color) ? item.color : ''
    summary.itemName = !isUndefined(item.itemName) ? item.itemName : ''
    summary.manufacturer = !isUndefined(item.manufacturer) ? item.manufacturer : ''
    summary.modelNumber = !isUndefined(item.modelNumber) ? item.modelNumber : ''
    summary.sizeName = !isUndefined(item.size) ? item.size : ''
    summary.styleName = !isUndefined(item.style) ? item.style : ''
  }
  return summary
}

function itemProductType(productTypes) {
  let type = ''
  if (
    !isEmpty(productTypes)
    && !isUndefined(productTypes)
    && isArray(productTypes)
    && gt(productTypes.length, 0)) {
    const productType = head(productTypes)
    if (!isUndefined(productType.productType)) {
      type = productType.productType
    }
  }
  return type
}

function itemIdentifier(identifiers) {
  const identifier = {
    identifierType: '',
    identifier: '',
  }
  if (
    !isEmpty(identifiers)
    && !isUndefined(identifiers)
    && isArray(identifiers)
    && gt(identifiers.length, 0)
  ) {
    let identifierHead = head(identifiers)
    if (
      !isEmpty(identifierHead)
      && !isUndefined(identifierHead.identifiers)
      && isArray(identifierHead.identifiers)
      && gt(identifierHead.identifiers.length, 0)) {
      const upc = find(identifierHead.identifiers, p => p.identifierType === 'UPC')
      if (!isEmpty(upc)) {
        identifier.identifierType = upc.identifierType
        identifier.identifier = upc.identifier
      } else {
        identifierHead = head(identifierHead.identifiers)
        identifier.identifierType = !isUndefined(identifierHead.identifierType) ? identifierHead.identifierType : ''
        identifier.identifier = !isUndefined(identifierHead.identifier) ? identifierHead.identifier : ''
      }
    }
  }
  return identifier
}

export {
  CatalogItem as default,
  itemRank,
  itemRank1,
  itemIdentifier,
  itemProductType,
  itemSummary,
  itemImageLink,
}
