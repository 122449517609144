import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // planType
  async fetchAllPlanTypes() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findAllPlanTypes())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createPlanType(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.planManagement.Controller.createPlanType(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updatePlanType(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.planManagement.Controller.updatePlanType(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deletePlanTypeById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.planManagement.Controller.deletePlanTypeById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // planManagement
  async fetchPlanById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findPlanById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllPlans() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findAllPlans())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllByParentId(id, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findAllByParentId(id, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllByWarehouse(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findAllByWarehouse(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllPlansByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.planManagement.Controller.findAllPlansByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createPlan(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.planManagement.Controller.createPlan(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updatePlan(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.planManagement.Controller.updatePlan(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deletePlanById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.planManagement.Controller.deletePlanById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // planEnrollment
  async fetchUserPlanById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findUserPlanById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllForCurrentUser() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findAllForCurrentUser())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllMyUserPlansByQuery(params, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findAllMyUserPlansByQuery(params, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllUserPlansByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.planManagement.Controller.findAllUserPlansByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllPlansByCompanyId(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findAllPlansByCompanyId(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllPlansByStatus(status) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.planManagement.Controller.findAllPlansByStatus(status))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createPlanEnrollment(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.planManagement.Controller.createPlanEnrollment(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deleteEnrollmentById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.planManagement.Controller.deleteEnrollmentById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async changeStatus(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.planManagement.Controller.changeStatus(params))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async leavePlan(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.planManagement.Controller.leavePlan(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async applyDiscount(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.planManagement.Controller.applyDiscount(params))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
}
