/* eslint-disable no-unused-vars */
import { ref, nextTick } from '@vue/composition-api'

const JsBarcode = require('jsbarcode')

export default function useBarcodeHelper() {
  const barcodeOptions = {
    format: 'CODE128',
    width: 2,
    height: 150,
    displayValue: true,
    text: 'PrepShipHub',
    fontOptions: '',
    font: 'monospace',
    textAlign: 'center',
    textPosition: 'bottom',
    textMargin: 2,
    fontSize: 20,
    background: '#ffffff',
    lineColor: '#000000',
    margin: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  }

  function downloadBarcodeImage(target, barcode) {
    target.classList.add('d-none')
    const opt = { ...barcodeOptions }
    const barcodeText = barcode
    opt.text = barcode
    JsBarcode(`#${target.id}`, barcodeText, opt)

    nextTick(async () => {
      const serializer = new XMLSerializer()
      const svgData = serializer.serializeToString(target)
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const img = document.createElement('img')
      img.setAttribute('src', `data:image/svg+xml;base64,${btoa(svgData)}`)
      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        const imgUrl = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')
        const downloadTag = document.createElement('a')
        downloadTag.href = imgUrl
        downloadTag.download = `${barcode}.png`
        downloadTag.click()
      }
    })
  }

  function createFnskuLabel(barcode) {
    const canvas = document.createElement('canvas')
    JsBarcode(canvas, barcode, {
      format: 'CODE128',
      displayValue: true,
      width: 1,
      margin: 6,
      height: 60,
      font: 'sans-serif',
      fontSize: 16,
    })
    const data = canvas.toDataURL('image/png')
    return data
  }

  return {
    downloadBarcodeImage,
    createFnskuLabel,
  }
}
