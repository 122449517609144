<template>
  <b-card>
    <div v-if="hasPermission || isIndividualTenant" class="row mb-1">
      <div class="col-6 d-flex align-items-center">
        <div v-if="isServingTenant" class="d-flex d-flex-row justify-content-start">
          <h5 class="my-0 mr-half">
            Acc or Suite #: <strong class="text-warning">{{ accountInfo.accountNo }}</strong>
          </h5>
          <h5 class="my-0 mr-half">
            Company: <strong class="text-warning">{{ accountInfo.companyName }}</strong>
          </h5>
          <!-- <h5 class="my-0 mr-half">
            Account Manager: <strong class="text-warning">{{ accountInfo.accountManager }}</strong>
          </h5> -->
      </div>
      </div>
      <div v-if="hasPermission || isIndividualTenant" class="col-6 text-right">
        <div class="d-flex d-flex-row justify-content-end">
          <dx-util-check-box
            v-model="autoPrint"
            class="text-warning font-weight-bolder mr-1"
            text="Auto Print FNSKU Label"
          />
          <dx-util-select-box
            id="printer-select-box"
            v-model="selectedPrinter"
            :data-source="printers"
            display-expr="Name"
          />
          <b-button class="mx-half" variant="primary" @click="onClickSendInventory">
            <feather-icon icon="UploadCloudIcon" class="mr-50" />
            <span class="align-middle">Move To Inventory</span>
          </b-button>
          <b-button class="mr-half" variant="success" @click="onClickCreateBatch">
            <feather-icon icon="CodepenIcon" class="mr-50" />
            <span class="align-middle">Add To Batch</span>
          </b-button>
          <dx-util-button
              v-if="isServingTenant"
              type="danger"
              text="LPNs"
              icon="contentlayout"
              @click="onClickShowLpns"
            />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="itemScanGrid"
          ref="itemScanGridRef"
          :height="setLocalHeight"
          :data-source="ItemSortDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          :repaint-changes-only="true"
          @cell-dbl-click="onCellDblClick"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing"
          @editor-preparing="onEditorPreparing"
          @row-removed="onSortedItemRemoved"
        >
          <!--region DataGird base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
          <dx-search-panel :highlight-search-text="true" :visible="true" :search-visible-columns-only="true" :width="200" />
          <dx-selection show-check-boxes-mode="always" select-all-mode="page" :allow-select-all="true" :width="10" mode="multiple" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!--endregion DataGird base components -->
          <!-- region Actions -->
          <dx-column :visible="hasPermission" data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                ref="printFnsku"
                type="default"
                icon="bi bi-printer-fill"
                class="mr-half"
                hint="Print FNSKU"
                @click="onClickPrintFnsku(data)"
              />
              <dx-util-button
                ref="btnSelectedTrackingNumber"
                type="success"
                icon="selectall"
                class="mr-half"
                hint="Select Tracking Number"
                @click="onClickSetSelectedTrackingNumber"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash"
                hint="Delete this sorted item"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
          <!-- endregion -->
          <dx-column data-field="itemAction" caption="Action" alignment="center" cell-template="itemActionCell" />
          <template #itemActionCell="{ data }">
            <span class="d-block badge" :class="getItemActionFormated(data.value).variant">
              {{ getItemActionFormated(data.value).text }}
            </span>
          </template>
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" caption="Image" alignment="center" :allow-sorting="false" :width="88" />
          <template #imageCellTemplate="{data}">
            <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column data-field="title" caption="Title" cell-template="titleCellTemplate" :min-width="300" />
          <template #titleCellTemplate="{data}">
            <div class="dx-product-title">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="productType" caption="Type" />
          <dx-column data-field="tracking" />
          <dx-column data-field="palletId" caption="Pallet Id" />
          <dx-column data-field="orderNumber" cell-template="orderNumberCellTemplate" />
          <template #orderNumberCellTemplate="{data}">
            <dx-util-text-box
              :ref="`textbox-order-number-${data.data.id}`"
              :read-only="!hasPermission"
              :value="data.value"
            >
              <dx-text-box-button
                v-if="hasPermission"
                :name="`order-number-${data.data.id}`"
                :options="orderNumberTextBoxButton(data.data)"
                location="after"
              />
            </dx-util-text-box>
          </template>
          <dx-column data-field="upcCode" caption="UPC/Serial" width="180" />
          <dx-column data-field="asin" caption="ASIN" width="120" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="clientShipmentItemId" alignment="left" caption="Matched" cell-template="matchStatusCell" width="90" />
          <template #matchStatusCell="{ data }">
            <span class="d-block badge" :class="getMatchedFormated(data.value).variant">
              {{ getMatchedFormated(data.value).text }}
            </span>
          </template>
          <dx-column data-field="quantity" width="125" cell-template="qauntityCellTemplate" alignment="left" />
          <template #qauntityCellTemplate="{data}">
            <dx-util-number-box
              v-if="data.value"
              :ref="`textbox-quantity-${data.data.id}`"
              :read-only="!hasPermission"
              :value="data.value"
            >
              <dx-text-box-button
                v-if="hasPermission"
                :name="`quantity-${data.data.id}`"
                :options="quantityTextBoxButton(data.data)"
                location="after"
              />
            </dx-util-number-box>
          </template>
          <dx-column data-field="storeName" />
          <dx-column data-field="unloadedAt" data-type="date" caption="Sorted At" width="90" />
          <dx-column :visible="hasPermission" data-field="sortedByName" caption="Sorted By" />
          <!-- region Toolbar -->
          <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1 w-25">
                <input type="text"
                  placeholder="Scan Code"
                  class="form-control"
                  :read-only="isReadOnlyWait"
                  @keydown.enter="onEnterScanCode($event)"
                >
              </div>
              <div v-if="hasPermission" class="mr-1 w-15">
                <dx-util-select-box
                  v-model="codeType"
                  :data-source="codeTypes"
                  display-expr="text"
                  value-expr="value"
                />
              </div>
              <div v-if="hasPermission" class="mr-1 w-25">
                <dx-util-text-box
                  v-model.trim="itemSort.tracking"
                  placeholder="Tracking Number"
                />
              </div>
              <div v-if="hasPermission" class="mr-1 w-25">
                <dx-util-text-box
                  v-model.trim="itemSort.orderNumber"
                  placeholder="Order Number"
                />
              </div>
              <div class="mr-1 w-15">
                <dx-util-date-box
                  v-model="filters.beginDate"
                  :max="filters.endDate"
                  type="date"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-15">
                <dx-util-date-box
                  v-model="filters.endDate"
                  :min="filters.beginDate"
                  type="date"
                  @value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>
          <!-- endregion -->
        </dx-data-grid>
      </div>
      <!-- #region Move Invetory -->
      <div class="col-12 px-0">
        <dx-util-popup
          ref="sortedItemMoveInventoryPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :full-screen="true"
          content-template="popup-content"
          title="Selected Sorted Item Move to Inventory"
          @hidden="onHiddenMoveInventoryPopup"
        >
          <dx-util-position at="center" my="center" />
          <template #popup-content>
            <sorted-item-move-invetory v-if="renderMoveInvetoryComponent" :move-inventory-data="moveInventoryData" @on-action-move-inventory="onActionMoveInventory" />
          </template>
        </dx-util-popup>
      </div>
      <!-- #endregion -->
      <!-- #region Create Batch -->
      <div class="col-12 px-0">
        <dx-util-popup
          ref="createBatchFormPopupRef"
          :show-close-button="false"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="480"
          :height="360"
          title="Create Batch"
          @hidden="onHiddenCreateBatchFormPopup"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
          <template #cancelPopupButton>
            <dx-util-button id="cancelCreateBatchButton" text="Cancel" type="danger" @click="onCancelCreateBatch()" />
          </template>
          <dx-util-toolbar-item toolbar="bottom" location="after" template="submitPopupButton" />
          <template #submitPopupButton>
            <dx-util-button id="submitCreateBatchButton" text="Save" type="default" @click="onSubmitCreateBatch()" />
          </template>
          <dx-util-form ref="createBatchFormRef" :form-data="createBatchFormData" :col-count="1" :show-colon-after-label="true" label-location="top">
            <!-- <dx-util-simple-item data-field="name"> -->
                <!-- <dx-util-required-rule message="This field is required" /> -->
            <!-- </dx-util-simple-item> -->
            <dx-util-simple-item data-field="location">
                <!-- <dx-util-required-rule message="This field is required" /> -->
            </dx-util-simple-item>
            <dx-util-simple-item data-field="parentLocation">
                <!-- <dx-util-required-rule message="This field is required" /> -->
            </dx-util-simple-item>
          </dx-util-form>
        </dx-util-popup>
      </div>
      <!-- #endregion -->
      <!-- #region print fnsku label-->
      <div class="col-12 px-0">
        <dx-util-popup
          ref="printFnskuSelectionPopupRef"
          :show-close-button="false"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          title="Print FNSKU Label"
          content-template="popup-content"
          @hidden="onHidenPrintFnskuSelectionPopup"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelSelectedFnskuLabelButton" />
          <template #cancelSelectedFnskuLabelButton>
            <dx-util-button id="cancelSelectedFnskuLabel" text="Cancel" type="danger" @click="onCancelSelectedFnskuLabel()" />
          </template>
          <template #popup-content>
            <div>
              <table class="table table-striped table-bordered table-condensed">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>UPC</th>
                    <th>ASIN</th>
                    <th>MSKU</th>
                    <th>FNSKU</th>
                    <th>Quantity</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in printFnskuList" :key="index">
                    <td>{{ item.title }}</td>
                    <td>{{ item.upc }}</td>
                    <td>{{ item.asin }}</td>
                    <td>{{ item.msku }}</td>
                    <td>{{ item.fnsku }}</td>
                    <td>
                      <dx-util-number-box
                        :value="item.quantity"
                        :element-attr="inputClass"
                        :min="1"
                        :show-spin-buttons="true"
                        @value-changed="setSelectedFnskuPrintCount($event, item)"
                      />
                    </td>
                    <td>
                      <dx-util-button type="default" icon="print" @click="onPrintSelectedFnskuLabel(item)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </dx-util-popup>
      </div>
      <!-- #endregion -->
      <div class="col-md-12 px-0">
        <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
      </div>
      <!-- #region lpns-->
      <div class="col-12 px-0">
        <dx-util-popup
          ref="lpnsPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :full-screen="true"
          title="LPNs"
          content-template="popup-content"
          @shown="onShownLpnPopup"
          @hidden="onHiddenLpnPopup"
        >
          <template #popup-content>
            <div class="row">
                <lpn-list :component-id="lpnsComponentId" />
            </div>
          </template>
        </dx-util-popup>
      </div>
      <!-- #endregion-->
    </div>
  </b-card>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid'
import usePrinter from '@/libs/printer/print'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import sortItemService from '@/http/requests/inbound/sort-item.service'
import companyService from '@/http/requests/company/companyService'
import ItemSortFilter from '@/http/models/search-filters/ItemSortFilter'
import useCurrentUser from '@/libs/app/current-user'
import { isValid } from 'gtin'
import CoolLightBox from 'vue-cool-lightbox'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import useConfirmation from '@/libs/app/confirmation'
import Pager from '@core/dev-extreme/utils/pager'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import { Notify, TruncateTracking } from '@robustshell/utils/index'
import itemActionEnum, { getItemActionEnumList } from '@/enums/item-action.enum'
import { isValidScanedUpc } from '@/views/apps/ship/validationItem'
import shipService from '@/http/requests/ship/shipService'
import LpnList from '@/views/apps/warehouse-management/view/LpnList.vue'
import itemSortStatusEnum, { getItemSortStatusEnumList } from './item-sort-status.enum'
import { ItemSortDataSource } from './item-sort.store'
import SortedItemMoveInvetory from './SortedItemMoveInvetory.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    SortedItemMoveInvetory,
    DxTextBoxButton,
    LpnList,
    'light-box': CoolLightBox,
  },
  mixins: [GridBase],
  setup() {
    const { pshConfirm } = useConfirmation()
    const {
      printers,
      printFnskuLabel,
    } = usePrinter()
    const {
      userId,
      userWarehouseId,
      isServingTenant,
      isIndividualTenant,
    } = useCurrentUser()
    return {
      pshConfirm,
      printers,
      userId,
      printFnskuLabel,
      userWarehouseId,
      isServingTenant,
      isIndividualTenant,
    }
  },
  data() {
    const oneMonthAgo = new Date()
    const plusOneDay = new Date()
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
    plusOneDay.setDate(plusOneDay.getDate() + 1)
    return {
      ItemSortDataSource,
      accountNo: '',
      companyId: 0,
      accountInfo: {
        accountNo: 0,
        accountManager: '-',
        companyName: '-',
      },
      filters: {
        query: '',
        itemAction: itemActionEnum.ALL.value,
        status: itemSortStatusEnum.OPEN.value,
        beginDate: oneMonthAgo,
        endDate: plusOneDay,
      },
      defaultItemSort: {},
      itemSort: {
        accountNo: null,
        companyId: 0,
        warehouseId: 0,
        palletId: null,
        code: '',
        codeType: '',
        quantity: 1,
        warehouseLocation: null,
        locName: '',
        sortedBy: null,
        tracking: '',
        orderNumber: null,
      },
      codeTypes: [
        { value: 'TRACKING', text: 'Tracking' },
        { value: 'UPC', text: 'Upc' },
        { value: 'SERIAL', text: 'Serial' },
        { value: 'PALLET', text: 'Pallet' },
        { value: 'AMAZON_ID', text: 'Amazon ID' },
      ],
      codeType: 'TRACKING',
      renderMoveInvetoryComponent: false,
      moveInventoryData: {
        companyId: null,
        warehouseId: null,
        storeId: 0,
        planId: 0,
        itemAction: '',
        location: '',
        parentLocation: '',
        selectedItems: [],
      },
      createBatchFormData: {
        name: '',
        location: '',
        parentLocation: '',
        itemIds: [],
      },
      isReadOnlyWait: false,
      defaultImg: defaultImageUrl,
      images: [],
      index: null,
      autoPrint: false,
      selectedPrinter: null,
      printFnskuList: [],
      inputClass: {
        class: 'w-100',
      },
      lpnsComponentId: '',
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    printFnskuSelectionPopup() {
      const popup = this.$refs.printFnskuSelectionPopupRef.instance
      return popup
    },
    lpnPopup() {
      const popup = this.$refs.lpnsPopupRef.instance
      return popup
    },
    dataGrid() {
      const dataGridIns = this.$refs.itemScanGridRef.instance
      return dataGridIns
    },
    moveInventoryPopup() {
      const popupIns = this.$refs.sortedItemMoveInventoryPopupRef.instance
      return popupIns
    },
    createBatchPopup() {
      const popupIns = this.$refs.createBatchFormPopupRef.instance
      return popupIns
    },
    warehouseId() {
      return this.userWarehouseId
    },
    setLocalHeight() {
      return window.innerHeight / 1.25
    },
  },
  mounted() {
    this.defaultItemSort = { ...this.itemSort }
    this.getUrlParams()
      .then(() => {
        this.loadData()
      })
      .then(() => {
        this.getAccountManager()
      })
  },
  beforeDestroy() {
    this.dataGrid.dispose()
    this.dataGrid.remove()
  },
  methods: {
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    getMatchedFormated(clientShipmentItemId) {
      if (clientShipmentItemId) return { variant: 'badge-light-success', text: 'Matched' }
      return { variant: 'badge-light-warning', text: 'Not Matched' }
    },
    getItemActionFormated(itemAction) {
      if (!itemAction) return { variant: 'badge-light-danger', text: 'Unknown' }
      if (itemAction === 'FBA') return { variant: 'badge-light-success', text: 'FBA' }
      if (itemAction === 'FBM') return { variant: 'badge-light-primary', text: 'FBM' }
      if (itemAction === 'DROPSHIP') return { variant: 'badge-light-warning', text: 'Dropship' }
      if (itemAction === 'FORWARDING') return { variant: 'badge-light-warning', text: 'Forwarding' }
      return { variant: 'badge-light-secondary', text: itemAction }
    },
    async getUrlParams() {
      const { accountNo, companyId } = this.$route.params
      this.accountNo = Number(accountNo)
      this.companyId = Number(companyId)
      this.accountInfo = {
        ...this.accountInfo,
        accountNo: this.accountNo,
      }
    },
    // Please check whether this endpoint is used. Possibly @deprecated
    getAccountManager() {
      companyService.fetchAccountManagerByAccountNo(this.accountNo).then(result => {
        const { data } = result
        if (data && data.fullName) {
          this.accountInfo = {
            ...this.accountInfo,
            accountNo: this.accountNo,
            accountManager: data.fullName,
            companyName: data.companyName,
          }
        }
      })
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })

      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All',
              icon: 'exportselected',
            },
            {
              id: 'selected',
              name: 'selected',
              icon: 'columnfield',
            },
          ],
          width: 120,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All',
          onItemClick: arg => {
            if (arg.itemData.id === 'all') {
              this.onDownloadSortedItems()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadSelectedSortedItems()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxSelectBox',
        options: {
          dataSource: getItemSortStatusEnumList(),
          value: itemSortStatusEnum.OPEN.value,
          valueExpr: 'value',
          displayExpr: 'text',
          onValueChanged: args => {
            this.filters.status = args.value
            this.searchByFilter()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxSelectBox',
        options: {
          dataSource: getItemActionEnumList(),
          value: itemActionEnum.ALL.value,
          valueExpr: 'value',
          displayExpr: 'text',
          onValueChanged: args => {
            this.filters.itemAction = args.value
            this.searchByFilter()
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reloadData()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    reloadData() {
      this.searchByFilter()
    },
    loadData() {
      ItemSortFilter.setDefaultFilters()
      ItemSortFilter.accountNo = this.accountNo
      ItemSortFilter.companyId = this.companyId
      ItemSortFilter.warehouseId = this.warehouseId
      ItemSortFilter.status = this.filters.status
      ItemSortFilter.itemAction = this.filters.itemAction
      const filters = ItemSortFilter.getFilters()
      ItemSortDataSource.searchValue(filters)
      ItemSortDataSource.load()
    },
    searchByFilter() {
      ItemSortFilter.accountNo = this.accountNo
      ItemSortFilter.companyId = this.companyId
      ItemSortFilter.warehouseId = this.warehouseId
      ItemSortFilter.q = this.filters.query
      ItemSortFilter.status = this.filters.status
      ItemSortFilter.itemAction = this.filters.itemAction
      const filters = ItemSortFilter.getFilters()
      ItemSortDataSource.searchValue(filters)
      ItemSortDataSource.reload()
    },
    // #region Download Sorted Items
    async onDownloadSortedItems() {
      ItemSortFilter.accountNo = this.accountNo
      ItemSortFilter.companyId = this.companyId
      ItemSortFilter.warehouseId = this.warehouseId
      ItemSortFilter.q = this.filters.query
      ItemSortFilter.status = this.filters.status
      ItemSortFilter.itemAction = this.filters.itemAction
      ItemSortFilter.beginDate = this.filters.beginDate
      ItemSortFilter.endDate = this.filters.endDate
      const filters = ItemSortFilter.getFilters()
      const pager = new Pager({})
      const response = await sortItemService.fetchItemsByQuery(filters, pager.staticPageable)
      const data = response.data.body.content
      this.onDownloadExportData(data)
    },
    onDownloadSelectedSortedItems() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Sorted')
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 100 },
        { header: 'Tracking', key: 'tracking', width: 24 },
        { header: 'Pallet Id', key: 'palletId', width: 18 },
        { header: 'Order Number', key: 'orderNumber', width: 24 },
        { header: 'UPC', key: 'upcCode', width: 14 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'Action', key: 'itemAction', width: 12 },
        { header: 'Sorted In This Batch', key: 'quantity', width: 9 },
        { header: 'Uploaded', key: 'uploadedQty', width: 9 },
        { header: 'Total Sorted', key: 'sortedQty', width: 12 },
        { header: 'Store Name', key: 'storeName', width: 9 },
        { header: 'Shipped', key: 'shippedQty', width: 9 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:L1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.tracking,
          item.palletId,
          item.orderNumber,
          item.upcCode,
          item.asin,
          item.itemAction,
          item.quantity,
          item.uploadedQty,
          item.sortedQty,
          item.storeName,
          item.shippedQty,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'SortedItems.xlsx',
        )
      })
    },
    // #endregion
    // #region Move to Inventory
    onClickSendInventory() {
      const selectedRows = this.dataGrid.getSelectedRowsData()
      if (selectedRows.length === 0) {
        Notify.warning('Please select item from the table to move to inventory.')
        return
      }
      this.moveInventoryData = {
        ...this.moveInventoryData,
        companyId: this.companyId,
        warehouseId: this.warehouseId,
        assignLpn: false,
        selectedItems: selectedRows,
      }
      this.moveInventoryPopup.show()
      this.$nextTick(() => {
        this.renderMoveInvetoryComponent = true
      })
    },
    onHiddenMoveInventoryPopup() {
      this.$nextTick(() => {
        this.renderMoveInvetoryComponent = false
      })
    },
    onActionMoveInventory() {
      this.moveInventoryPopup.hide()
      this.dataGrid.deselectAll()
      this.reloadData()
    },
    // #endregion
    // #region CRUD Actions
    onSortedItemRemoved() {
      this.searchByFilter()
    },
    onClickSetSelectedTrackingNumber(e) {
      const {
        tracking, orderNumber, locName, warehouseLocation,
      } = e.row.data
      this.codeType = 'TRACKING'
      this.itemSort.tracking = tracking
      this.itemSort.accountNo = this.accountNo
      this.itemSort.companyId = this.companyId
      this.itemSort.warehouseId = this.warehouseId
      this.itemSort.palletId = null
      this.itemSort.code = ''
      this.itemSort.codeType = ''
      this.itemSort.quantity = 1
      this.itemSort.warehouseLocation = warehouseLocation
      this.itemSort.locName = locName
      this.itemSort.sortedBy = this.userId
      this.itemSort.orderNumber = orderNumber
    },
    async onClickDelete(e) {
      const confirm = await this.pshConfirm(
        'Delete Confirmation',
        `<p>Are you sure you want to delete this Sorted Item record?</p>
         <h4 class="text-danger mt-half">This action cannot be undone.</h4>`,
      )
      if (confirm) {
        try {
          const result = await sortItemService.delete(e.row.data.id)
          if (result) {
            this.reloadData()
          }
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    initSortedItem() {
      this.itemSort.accountNo = this.accountNo
      this.itemSort.companyId = this.companyId
      this.itemSort.warehouseId = this.warehouseId
      this.itemSort.sortedBy = this.userId
      this.itemSort.autoPrint = this.autoPrint
    },
    async onEnterScanCode(e) {
      const scanedCode = e.target.value.trim()
      e.target.value = ''
      this.isReadOnlyWait = true
      this.setCodeValue(scanedCode)
      if (this.itemSort.tracking && this.itemSort.code) {
        this.initSortedItem()
        const result = await ItemSortDataSource.store().insert(this.itemSort)
        const data = result.data
        this.itemSort.code = ''
        this.itemSort.codeType = ''
        if (this.autoPrint && data && data.length > 0) {
          const dataHead = data[0]
          if (dataHead.fbaInventorySummaries && dataHead.fbaInventorySummaries.length === 1) {
            const head = dataHead.fbaInventorySummaries[0]
            const item = {
              fnsku: head.fnSku,
              title: head.productName,
              expirationDate: '',
              quantity: data.length,
            }
            this.labelPrintManual(item)
          }
          if (dataHead.fbaInventorySummaries && dataHead.fbaInventorySummaries.length > 1) {
            this.setPrintableFnskuList(dataHead.fbaInventorySummaries, dataHead.quantity, dataHead.code)
          }
        }
        this.reloadData()
      }
      this.isReadOnlyWait = false
    },
    setCodeValue(code) {
      if (!code) return
      this.itemSort.code = null
      try {
        if (isValid(code)) {
          if (!this.itemSort.tracking) {
            Notify.warning('You have to enter a tracking number before scanning a code!')
            return
          }
          this.itemSort.code = code
          this.itemSort.codeType = 'UPC'
          this.codeType = 'UPC'
        } else {
          this.setCode(code)
        }
      } catch (error) {
        this.setCode(code)
      }
    },
    setCode(code) {
      if (isValidScanedUpc(code)) {
        if (!this.itemSort.tracking) {
          Notify.warning('You have to enter a tracking number before scanning a code!')
          return
        }
        this.itemSort.code = code
        this.itemSort.codeType = 'UPC'
        this.codeType = 'UPC'
      } else if (this.codeType === 'SERIAL') {
        this.itemSort.code = code
        this.itemSort.codeType = this.codeType
      } else if (this.codeType === 'PALLET') {
        this.itemSort.palletId = code
        this.itemSort.codeType = this.codeType
      } else if (this.codeType === 'AMAZON_ID') {
        this.itemSort.code = code
        this.itemSort.codeType = 'AMAZON_ID'
      } else {
        this.itemSort.tracking = TruncateTracking(code)
        this.itemSort.codeType = 'TRACKING'
        this.codeType = 'TRACKING'
      }
    },
    orderNumberTextBoxButton(data) {
      return {
        icon: 'save',
        type: 'success',
        elementAttr: { id: `order-number-${data.id}` },
        onClick: e => {
          const orderNumberTextBoxRef = `textbox-${e.component.element().id}`
          const orderNumberTextBox = this.$refs[orderNumberTextBoxRef].instance
          const orderNumber = orderNumberTextBox.option('value')
          const payload = {
            id: data.packageScanId,
            orderNumber: orderNumber,
          }
          this.onClickSortedItemOrderNumberUpdate(payload)
        },
      }
    },
    quantityTextBoxButton(data) {
      return {
        icon: 'save',
        type: 'success',
        elementAttr: { id: `quantity-${data.id}` },
        onClick: e => {
          const qtyTextBoxRef = `textbox-${e.component.element().id}`
          const qtyTextBox = this.$refs[qtyTextBoxRef].instance
          const newQty = qtyTextBox.option('value')
          const payload = {
            id: data.id,
            quantity: newQty,
          }
          this.onClickSortedItemUpdate(payload)
          if (this.autoPrint) {
            let quantity = newQty - data.quantity
            quantity = quantity > 0 ? quantity : 1
            this.onUpdatedQuantityPrintFnsku(data, quantity)
          }
        },
      }
    },
    async onClickSortedItemUpdate(payload, data) {
      await ItemSortDataSource.store().update('quantity', payload)
      this.reloadData()
    },
    async onClickSortedItemOrderNumberUpdate(payload) {
      await ItemSortDataSource.store().update('orderNumber', payload)
      this.reloadData()
    },
    // #endregion
    // #region Create Batch
    onClickCreateBatch() {
      const selectedRows = this.dataGrid.getSelectedRowsData()
      if (selectedRows.length === 0) {
        Notify.warning('Please select item from the table to create batch.')
        return
      }
      const items = selectedRows.map(p => p.id)

      this.createBatchFormData.name = ''
      this.createBatchFormData.location = ''
      this.createBatchFormData.parentLocation = ''
      this.createBatchFormData.itemIds = [...items]
      this.createBatchPopup.show()
    },
    onSubmitCreateBatch() {
      const form = this.$refs.createBatchFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        sortItemService.batchSortedItems(this.createBatchFormData).then(() => {
          this.createBatchPopup.hide()
          this.dataGrid.deselectAll()
        }).then(() => {
          this.reloadData()
        })
      }
    },
    onCancelCreateBatch() {
      this.createBatchPopup.hide()
    },
    onHiddenCreateBatchFormPopup() {
      this.createBatchFormData.name = ''
      this.createBatchFormData.location = ''
      this.createBatchFormData.parentLocation = ''
      this.createBatchFormData.itemIds = []
    },
    // #endregion
    // #region Print Fnsku
    onCancelSelectedFnskuLabel() {
      this.printFnskuSelectionPopup.hide()
    },
    onHidenPrintFnskuSelectionPopup() {
      this.printFnskuList.length = 0
    },
    setSelectedFnskuPrintCount(e, item) {
      item.quantity = e.value
    },
    onPrintSelectedFnskuLabel(selected) {
      const item = {
        fnsku: selected.fnsku,
        title: selected.title,
        expirationDate: '',
        quantity: selected.quantity,
      }
      this.labelPrintManual(item)
      this.printFnskuSelectionPopup.hide()
    },
    async onClickPrintFnsku(e) {
      const { data } = e.row
      const result = await shipService.amazonInventorySummary(data.clientShipmentItemId)
      if (result.length === 0) {
        Notify.warning('Item is not assigned with an MSKU')
        return
      }
      this.setPrintableFnskuList(result, data.quantity, data.upcCode)
    },
    async onUpdatedQuantityPrintFnsku(data, quantity) {
      const result = await shipService.amazonInventorySummary(data.clientShipmentItemId)
      this.setPrintableFnskuList(result, quantity, data.upcCode)
    },
    setPrintableFnskuList(result, quantity, upc) {
      if (result.length > 1) {
        this.printFnskuList.length = 0
        result.forEach(item => {
          this.printFnskuList.push({
            asin: item.asin,
            msku: item.sellerSku,
            fnsku: item.fnSku,
            title: item.productName,
            upc: upc,
            expirationDate: '',
            quantity: quantity,
          })
        })
        this.printFnskuSelectionPopup.show()
      } else {
        const head = result[0]
        const item = {
          fnsku: head.fnSku,
          title: head.productName,
          expirationDate: '',
          quantity: quantity,
        }
        this.labelPrintManual(item)
      }
    },
    async labelPrintManual(item) {
      if (this.autoPrint && this.selectedPrinter.VendoreName) {
        const payload = {
          barcode: item.fnsku,
          title: item.title,
          expressionDate: this.formatedDate(item.expirationDate),
          copies: 1,
        }
        await this.printFnskuLabel(payload)
      }
    },
    // #endregion
    // #regin LPNs
    onClickShowLpns() {
      this.lpnPopup.show()
    },
    onShownLpnPopup() {
      this.lpnsComponentId = uuidv4()
    },
    onHiddenLpnPopup() {
      this.lpnsComponentId = ''
    },
    // #endregion
  },
}
</script>

<style lang="scss">

</style>
