/**
 * Enum for Customer Types
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const CompanyTypeEnum = Object.freeze({
  Warehouse: { value: 'warehouse', text: 'Warehouse' },
  Customer: { value: 'customer', text: 'Customer' },
  Franchise: { value: 'franchise', text: 'Franchise' },
  Vendor: { value: 'vendor', text: 'Vendor' },
  MainSoftwareCompany: { value: 'mainsoftwarecompany', text: 'Main Software Company' },
  SoftwareServiceVendor: { value: 'softwareservicevendor', text: 'Software Service Vendor' },
  list: () => Object.values(CompanyTypeEnum)
    .filter(value => typeof value === 'object')
    .map(value => ({
      value: value.value,
      text: value.text,
    })),
  find: query => Object.values(CompanyTypeEnum)
    .find(value => typeof value === 'object' && (value.value === query)),
})

export default CompanyTypeEnum
