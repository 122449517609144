import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {

  async fetchItemsByQuery(filters, pageable = {}) {
    const result = axios.post(endpoints.sortItem.controller.getItemsByQuery(pageable), filters)
    return result
  },

  async trackItemsByQuery(filters, pageable = {}) {
    const result = axios.post(endpoints.sortItem.controller.trackItemsByQuery(pageable), filters)
    return result
  },

  async getByClientShipmentItemId(clientShipmentItemId) {
    const result = axios.get(endpoints.sortItem.controller.getByClientShipmentItemId(clientShipmentItemId))
    return result
  },

  async fetchBatchesByQuery(filters, pageable = {}) {
    const result = axios.post(endpoints.sortItem.controller.getBatchesByQuery(pageable), filters)
    return result
  },

  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.sortItem.controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.sortItem.controller.create(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async update(data) {
    const result = axios.put(endpoints.sortItem.controller.update(), data)
    return result
  },

  async updateQuantity(data) {
    const result = axios.put(endpoints.sortItem.controller.updateQuantity(), data)
    return result
  },

  async updateOrderNumber(data) {
    const result = axios.put(endpoints.sortItem.controller.updateOrderNumber(data))
    return result
  },

  async moveItemToInventory(data) {
    const result = axios.put(endpoints.sortItem.controller.moveItemToInventory(), data)
    return result
  },

  async batchSortedItems(data) {
    const result = axios.put(endpoints.sortItem.controller.batchSortedItems(), data)
    return result
  },
  async setSortCompleted(batchId) {
    const result = axios.put(endpoints.sortItem.controller.setSortCompleted(batchId))
    return result
  },

  async delete(id) {
    const result = axios.delete(endpoints.sortItem.controller.delete(id))
    return result
  },
}
