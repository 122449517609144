import { custom } from 'devextreme/ui/dialog'
import { Notify } from '@/@robustshell/utils'

export default function useConfirmation() {
  /**
   * @param {string} message
   * @param {string} title
   * @param {boolean} requireInput
   * @param {string} confirmPlaceholder
   * @description Custom confirmation dialog with two buttons: Confirm and Cancel. Call this function like this: const confirm = pshConfirm('Are you sure?', 'Confirm', true, 'Type DELETE to confirm')
   * @returns {Promise<boolean>}
   */

  function renderHtmlMessage(message, icon, requireInput, confirmPlaceholder) {
    return `
    <div class="d-flex flex-column align-items-center w-100">
      <div class="d-flex flex-row justify-content-start">
        <div class="mr-1">
          <i class="bi bi-${icon} text-warning" style="font-size: 3rem;"></i>
        </div>
        <div class="mb-1" style="margin-top: 6px">
          <span class="display-h-4">${message}</span>
        </div>
      </div>
    ${requireInput ? `<div class="d-flex justify-content-center mt-2"><input type="text" id="confirmation-input" class="form-control" style="max-width: 320px;" placeholder="${confirmPlaceholder}"/></div>` : ''}
    </div>`
  }

  const pshConfirm = (
    title,
    message,
    icon = 'exclamation-circle-fill',
    okButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    buttonType = 'default',
    requireInput = false,
    confirmText = 'exclamation-circle-fill',
    confirmPlaceholder = 'Type DELETE to confirm',
  ) => custom({
    title: title,
    messageHtml: renderHtmlMessage(message, icon, requireInput, confirmText, confirmPlaceholder),
    dragEnabled: false,
    showTitle: true,
    buttons: [
      {
        text: okButtonText,
        type: buttonType,
        value: true,
        onClick: () => {
          if (requireInput) {
            const input = document.getElementById('confirmation-input').value
            const result = input === confirmText
            if (result) {
              return { result: true }
            }
            Notify.error('Invalid confirmation text')
          }
          return { result: true }
        },
      },
      {
        text: cancelButtonText,
        type: 'danger',
        stylingMode: 'contained',
        value: false,
        onClick: () => ({ result: false }),
      },
    ],
  }).show().then(data => data.result)

  return {
    pshConfirm,
  }
}
