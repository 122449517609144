/* eslint-disable no-restricted-globals */

import moment from 'moment'

const validate = dateStr => {
  if (!dateStr) return false
  const dateObj = new Date(dateStr)
  return !isNaN(dateObj) && dateObj.toString() !== 'Invalid Date'
}

const formatedDate = (dateStr, format = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}) => {
  if (!validate(dateStr)) return ''

  const dateObj = new Date(dateStr)

  const formatObj = new Intl.DateTimeFormat('en-US', format).format(dateObj)
  return formatObj
}

export const formatedDateStr = (dateStr, formatStr = 'YYYY-MM-DD') => {
  const dateObj = formatedDate(dateStr)
  if (!dateObj) return ''
  const result = moment(dateObj).format(formatStr)
  return result
}

export const formatedIsoDateStr = (dateStr, formatStr = 'YYYY-MM-DD') => {
  const dateObj = formatedDate(dateStr)
  if (!dateObj) return ''

  const result = moment(dateObj, formatStr).toISOString()
  return result
}
